import { ComponentProps, useState } from 'react';
import {
  Button,
  DialogClose,
  DialogContent,
  DialogHeader,
} from '@spektr/client/components';
import {
  IncompleteRuleGroup,
  NodeDetailsDialogTitle,
  RuleGroupBody,
  RuleGroupCard,
  RuleGroupHeader,
  RuleGroupProvider,
  TitleInput,
  parseRuleGroup,
} from '@spektr/shared/components';
import { FormNodeSchema, RuleGroup } from '@spektr/shared/validators';
import { LoopFormBuilder } from '@spektr/client/loop-form-builder';
import { useQuery } from '@tanstack/react-query';
import { useParsedParams, useUpdateLoopNode } from '@spektr/shared/hooks';
import { processNodeParamsSchema } from '@spektr/shared/utils';
import { getAllowedSpektrFieldsQuery } from '@spektr/client/services';

export type FormNodeDialogContentProps = {
  node: FormNodeSchema;
} & ComponentProps<typeof DialogContent>;

const DEFAULT_RULE_GROUP: IncompleteRuleGroup = {
  clientSideOnlyId: 'default-rule-group-form-node',
  id: 'default-rule-group-form-node',
  title: 'Default Rule Group',
  score: undefined,
  rule: {
    isIntermediate: false,
    id: 'default-rule-group-form-node-rule',
    groupRoot: true,
  },
};

export const FormNodeDialogContent = ({
  node,
  ...rest
}: FormNodeDialogContentProps) => {
  const { processId } = useParsedParams(processNodeParamsSchema);
  const [group, setGroup] = useState<IncompleteRuleGroup>(
    node.rule ? parseRuleGroup(node.rule) : DEFAULT_RULE_GROUP
  );
  const [form, setForm] = useState(node.form ?? []);

  const parsedGroup = RuleGroup.safeParse(group);

  const { data: spektrfields } = useQuery(
    getAllowedSpektrFieldsQuery(processId, node.id)
  );

  const { mutate, isPending } = useUpdateLoopNode(processId);

  return (
    <DialogContent size="large" {...rest}>
      <div className="flex flex-col">
        <DialogHeader>
          <NodeDetailsDialogTitle
            left={
              <TitleInput
                title={group.title}
                onChange={(title) => setGroup({ ...group, title })}
              />
            }
            right={
              <div className="flex justify-center gap-2">
                <Button
                  color="cyan"
                  type="button"
                  size="sm"
                  disabled={isPending || !parsedGroup.success}
                  onClick={() => {
                    if (!parsedGroup.success) return;

                    mutate({
                      nodeId: node.id,
                      node: {
                        nodeType: 'form',
                        form: form,
                        rule: parsedGroup.data,
                      },
                    });
                  }}
                >
                  Save
                </Button>
                <DialogClose onClick={rest.requestStartExitAnimation} />
              </div>
            }
          />
        </DialogHeader>
        <RuleGroupProvider
          group={group}
          spektrFields={spektrfields ?? []}
          onChange={(updates) => {
            if (updates) setGroup({ ...group, ...updates });
          }}
        >
          <RuleGroupCard>
            <RuleGroupHeader>
              <TitleInput
                className="text-color-text-primary hover:ring-spektr-zinc-600 focus:ring-spektr-zinc-600 pl-1 text-sm"
                title={group.title ?? ''}
                onChange={(title) => setGroup({ ...group, title })}
              />
            </RuleGroupHeader>
            <RuleGroupBody />
          </RuleGroupCard>
        </RuleGroupProvider>
        <LoopFormBuilder
          widgets={form}
          spektrFields={spektrfields ?? []}
          onUpdate={setForm}
        />
      </div>
    </DialogContent>
  );
};
