import { cn } from '@spektr/client/utils';

import { useFormRouter } from '../../hooks/useFormRouter';

import { FormNavigationItem } from '../../components/FormNavigationItem';

export const FormNavigation = () => {
  const { isActiveRoute, navigateTo } = useFormRouter();

  return (
    <div
      className={cn(
        'flex shrink-0 flex-col gap-3',
        'h-full w-full',
        'dark:border-color-border-secondary border-color-border-tertiary border-r p-3',
        'bg-color-bg-secondary'
      )}
    >
      <FormNavigationItem
        isActive={isActiveRoute('blocks')}
        icon="Blocks"
        onClick={() => navigateTo('blocks')}
      />
      <FormNavigationItem
        isActive={isActiveRoute('fields')}
        icon="FilePlus"
        onClick={() => navigateTo('fields')}
      />
      <FormNavigationItem
        isActive={isActiveRoute('primitives')}
        icon="FileCog"
        onClick={() => navigateTo('primitives')}
      />
      <div className="bg-color-bg-form-navigation mx-0.5 h-[1px]" />
      <FormNavigationItem
        isActive={isActiveRoute('visuals')}
        icon="PencilRuler"
        onClick={() => navigateTo('visuals')}
      />
      <FormNavigationItem
        isActive={isActiveRoute('design')}
        icon="Palette"
        onClick={() => navigateTo('design')}
      />
    </div>
  );
};
