import { ArrowRight, GoalIcon, SearchIcon } from 'lucide-react';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { processBuilderNewUrl } from '@spektr/shared/utils';

import { Button, CalculatorIcon } from '@spektr/client/components';
import { cn } from '@spektr/client/utils';
import { ProcessType } from '@spektr/shared/validators';

type ProcessTypeWithoutLoop = Exclude<ProcessType, 'loop'>;

const labelVariant: Record<ProcessTypeWithoutLoop, ReactNode> = {
  risk: (
    <span>
      Create a <span className="text-color-yellow">Risk Process</span>
    </span>
  ),
  score: (
    <span>
      Create a <span className="text-color-blue">Customer Score Process</span>
    </span>
  ),
  monitoring: (
    <span>
      Create a <span className="text-color-cyan">Monitoring Process</span>
    </span>
  ),
};
const descriptionVariant: Record<ProcessTypeWithoutLoop, string> = {
  risk: "Build simple or complex decision models based on your data to assess your customers' risk groups.",
  score:
    'Build rules to categorise your customers based on value, fraud, credit or any type of score.',
  monitoring:
    "Build event driven or scheduled monitoring set ups to discover potential breach or enrich your customers' profile.",
};
const iconVariant: Record<ProcessTypeWithoutLoop, ReactNode> = {
  risk: <CalculatorIcon className="stroke-color-yellow h-5 w-5 stroke-2" />,
  score: <GoalIcon className="stroke-color-blue h-5 w-5 stroke-2" />,
  monitoring: (
    <SearchIcon className="stroke-color-cyan h-5 w-5 scale-x-[-1] stroke-2" />
  ),
};

const iconBackgroundVariant: Record<ProcessTypeWithoutLoop, string> = {
  risk: 'bg-color-yellow/20',
  score: 'bg-color-blue/20',
  monitoring: 'bg-color-green/20',
};

const textColorVariant: Record<ProcessTypeWithoutLoop, string> = {
  risk: 'text-color-yellow',
  score: 'text-color-blue',
  monitoring: 'text-color-cyan',
};

export type CreateProcessCardProcessCardProps = {
  variant: ProcessTypeWithoutLoop;
};

export const CreateProcessCardProcessCard = ({
  variant,
}: CreateProcessCardProcessCardProps) => {
  return (
    <Link
      to={{
        pathname: processBuilderNewUrl(),
        search: `?processType=${variant}`,
      }}
      state={{
        processType: variant,
      }}
    >
      <div
        className={cn(
          'flex flex-col sm:flex-row',
          'items-start sm:items-center',
          'gap-6',
          'h-full p-5',
          'bg-color-bg-primary',
          'rounded-md border',
          'hover:bg-color-bg-accent/30',
          'active:bg-color-bg-accent/30'
        )}
      >
        <div
          className={cn(
            iconBackgroundVariant[variant],
            'h-9 w-9 self-start rounded p-2'
          )}
        >
          {iconVariant[variant]}
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-color-text-primary text-base">
            {labelVariant[variant]}
          </p>
          <p className="text-color-text-subtext w-full text-sm sm:w-10/12">
            {descriptionVariant[variant]}
          </p>
        </div>
        <Button
          variant="text"
          endIcon={<ArrowRight className="h-5 w-5 stroke-2" />}
          className={cn(
            'h-5 p-0',
            'text-sm font-light',
            'hover:text-initial hover:bg-initial',
            textColorVariant[variant]
          )}
          data-cy={'create-' + variant + '-process'}
        >
          Create now
        </Button>
      </div>
    </Link>
  );
};
