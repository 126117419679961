import { cn } from '@spektr/client/utils';

import type { InputField } from '@spektr/moonraker-types';

export type InputContentProps = {
  field: InputField;
  before?: React.ReactNode;
  after?: React.ReactNode;
};

export const InputContent = ({ field, before, after }: InputContentProps) => {
  return (
    <div className="flex w-full flex-col gap-2">
      <div
        className={cn(
          'flex items-center gap-2',
          'h-9 w-full px-3',
          'border-color-border-input-inverted rounded-md border'
        )}
      >
        <span className="text-color-text-subtext text-sm font-medium">
          {before}
        </span>
        {field.attributes?.placeholder && (
          <span
            className={cn('text-color-text-subtext', 'text-xs font-medium')}
          >
            {field.attributes.placeholder}
          </span>
        )}
        <span className="text-color-text-subtext ml-auto text-sm font-medium">
          {after}
        </span>
      </div>
      <span
        className={cn(
          'h-0',
          'text-color-text-subtext',
          'text-xs font-medium',
          'transition-all',
          { 'h-4': !!field.attributes?.helperText }
        )}
      >
        {field.attributes?.helperText}
      </span>
    </div>
  );
};
