import { z } from 'zod';

import { datasetOrigins } from '../dataset';

export const scanRequestSchema = z.object({
  datasetId: z.string().min(1),
  datasetKey: z.string().min(1),
  datasetOrigin: z.enum(datasetOrigins),
  workspaceId: z.string().optional(),
});

export const executeRequestSchema = z.object({
  processId: z.string().min(1),
  workspaceId: z.string().min(1),
  versionId: z.string().min(1),
  datasetId: z.string().min(1),
  datasetKey: z.string().min(1),
  datasetOrigin: z.enum(datasetOrigins),
  processRunId: z.string().min(1),
});
