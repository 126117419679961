import { CommentMessageBlock } from '@spektr/shared/validators';

import { SlateDescendant, isSlateElement } from '../types';

import { isNotEmpty } from './isNotEmpty';

export const trimRichTextEditorContent = (comment: SlateDescendant[]) => {
  if (comment.find((value) => !isSlateElement(value))) {
    return comment;
  }

  if (isNotEmpty(comment[0]) && isNotEmpty(comment[comment.length - 1])) {
    return comment;
  }

  const indexOfFirstNotEmpty = comment.findIndex((p) => isNotEmpty(p));
  const indexOfLastNotEmpty = [...comment]
    .reverse()
    .findIndex((p) => isNotEmpty(p));

  const trimmedComment = [...comment].slice(
    indexOfFirstNotEmpty,
    comment.length - indexOfLastNotEmpty
  );

  return trimmedComment as CommentMessageBlock[];
};
