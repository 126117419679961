import { Text } from 'slate';

import { SlateDescendant, isSlateElement } from '../types';

export const isNotEmpty = (elem: SlateDescendant) => {
  return (
    isSlateElement(elem) &&
    elem.children.every(
      (child) => Text.isText(child) && child.text.trim() !== ''
    )
  );
};
