export const Logo = ({ className }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="30"
      viewBox="0 0 29 30"
      fill="none"
      className={className}
    >
      <rect
        opacity="0.5"
        x="28.6157"
        y="18.3721"
        width="27.3558"
        height="6.83991"
        rx="3.41995"
        transform="rotate(180 28.6157 18.3721)"
        fill="#2BBEAF"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.416 27.0379C24.4717 28.6738 22.3799 29.2342 20.7441 28.2897L2.97643 18.0301C1.34095 17.0857 0.780581 14.9944 1.72476 13.3588C2.6691 11.7229 4.76086 11.1624 6.39662 12.107L24.1643 22.3666C25.7998 23.311 26.3602 25.4023 25.416 27.0379Z"
        fill="#FDA7D9"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4021 2.96217C26.3463 4.59777 25.7859 6.68909 24.1504 7.63347L6.3827 17.8931C4.74694 18.8376 2.65518 18.2771 1.71084 16.6413C0.76666 15.0057 1.32703 12.9143 2.96251 11.97L20.7302 1.71035C22.366 0.765811 24.4577 1.32629 25.4021 2.96217Z"
        fill="#FCAC04"
      />
      <ellipse
        cx="4.67916"
        cy="14.9524"
        rx="3.41948"
        ry="3.41995"
        transform="rotate(180 4.67916 14.9524)"
        fill="white"
      />
      <ellipse
        cx="25.1962"
        cy="14.9521"
        rx="3.41948"
        ry="3.41995"
        transform="rotate(180 25.1962 14.9521)"
        fill="#2BBEAF"
      />
      <ellipse
        cx="22.4604"
        cy="25.226"
        rx="3.41948"
        ry="3.41995"
        transform="rotate(180 22.4604 25.226)"
        fill="#FDA7D9"
      />
      <ellipse
        cx="22.4604"
        cy="4.70627"
        rx="3.41948"
        ry="3.41995"
        transform="rotate(180 22.4604 4.70627)"
        fill="#FCAC04"
      />
    </svg>
  );
};
