import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

import {
  Input,
  Label,
  Button,
  toast,
  ThemeToggle,
} from '@spektr/client/components';
import { hasErrorMessage } from '@spektr/client/utils';
import { Card } from '@spektr/shared/components';

import { useFeatureFlags } from '@spektr/platform-hooks';

import authService from '../../auth/authService';
import { AuthContext } from '../../auth/auth-context';

const schema = z.object({
  given_name: z.string(),
  family_name: z.string(),
});
type FormValues = z.infer<typeof schema>;

export function SettingsAccountPage() {
  const { user, getCurrentUser } = useContext(AuthContext);
  const { spektrTheme } = useFeatureFlags();
  const {
    register,
    setValue,
    handleSubmit: handleFormSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      given_name: user?.given_name ?? '',
      family_name: user?.family_name ?? '',
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (values: FormValues) => {
      const { given_name, family_name } = schema.parse(values);

      await authService.changeName(given_name, family_name);
    },
    onSuccess: () => {
      getCurrentUser();
      toast.success({
        title: 'Account successfully updated!',
        description: 'Your account has been updated.',
      });
    },
    onError: (error) => {
      const message = hasErrorMessage(error)
        ? error.message
        : 'An error occured, please try again.';

      toast.error({
        title: "Your changes haven't been saved!",
        description: message,
      });
      throw error;
    },
  });

  const handleSubmit = async (data: FormValues) => {
    await updateMutation.mutateAsync(data);
  };

  useEffect(() => {
    if (user) {
      const given_name = user?.given_name ?? '';
      const family_name = user?.family_name ?? '';

      setValue('given_name', given_name);
      setValue('family_name', family_name);
    }
  }, [user, setValue]);

  const inputEmail = user?.email;

  return (
    <div className="flex flex-col gap-6 p-5">
      <div className="flex flex-col">
        <h1 className="text-color-text-primary text-lg font-medium">Account</h1>
        <p className="text-sm">Update your account details</p>
      </div>
      <Card className="max-w-sm">
        <form
          onSubmit={handleFormSubmit(handleSubmit)}
          className="flex max-w-sm flex-col gap-4 p-4"
        >
          <p className="text-color-text-primary text-base font-medium">
            Your details
          </p>
          <div className="flex flex-col gap-2">
            <Label htmlFor="given_name">First name</Label>
            <Input
              id="given_name"
              required
              disabled={updateMutation.isPending}
              placeholder="Enter your first name"
              {...register('given_name', { required: true })}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="family_name">Last name</Label>
            <Input
              id="family_name"
              required
              disabled={updateMutation.isPending}
              placeholder="Enter your last name"
              {...register('family_name', { required: true })}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="email">Email</Label>
            <Input
              name="email"
              value={inputEmail}
              disabled
              placeholder="Enter your email"
            />
          </div>
          <div className="inline">
            <Button
              disabled={updateMutation.isPending}
              type="submit"
              color="primary"
              name="status"
            >
              Save
            </Button>
          </div>
        </form>
      </Card>
      {spektrTheme && (
        <Card className="flex max-w-sm flex-col gap-4 p-4">
          <p className="text-color-text-primary text-base font-medium">
            Platform theme
          </p>
          <ThemeToggle />
        </Card>
      )}
    </div>
  );
}
