import {
  getLoopSourcesFromRootNode,
  getProcessOutcomesWithTitle,
} from '@spektr/shared/utils';

import { ChildItem, ProcessItem } from '@spektr/client/components';

import {
  LoopSource,
  Process,
  OutcomeNodeWithTitle,
} from '@spektr/shared/types';

export function createOutcomeChildItems(
  outcomes: OutcomeNodeWithTitle[],
  selectedOutcomes: LoopSource[]
) {
  return outcomes.map<ChildItem>((outcome) => ({
    id: outcome.id,
    label: outcome.title,
    type: 'outcome',
    isSelected: selectedOutcomes.some(
      (source) => source.outcomeId === outcome.id
    ),
  }));
}

export function extractItems(processes: Process[], loop?: Process) {
  const currentSources = loop ? getLoopSourcesFromRootNode(loop) : [];

  return processes.reduce((acc, process) => {
    const outcomes = getProcessOutcomesWithTitle(process);
    const selectedOutcomes = currentSources.filter(
      (source) => source.processId === process.id
    );

    if (outcomes.length) {
      acc.push({
        id: process.id,
        label: process.name,
        type: process.type,
        children: createOutcomeChildItems(outcomes, selectedOutcomes),
      });
    }

    return acc;
  }, [] as ProcessItem[]);
}

export function transformItemToSource(items: ProcessItem[]) {
  const output: LoopSource[] = [];

  items.forEach((item) => {
    item.children.forEach((child) => {
      if (child.isSelected) {
        output.push({
          processId: item.id,
          outcomeId: child.id,
        });
      }
    });
  });

  return output;
}
