import { useMeasure } from 'react-use';

import { cn } from '@spektr/client/utils';

import { Accordion, ScrollArea } from '@spektr/client/components';

import { useFormEngine } from '../../hooks/useFormEngine';
import { useFormRouter } from '../../hooks/useFormRouter';

import { EmptyContent } from './components/EmptyContent';
import { ConfigHeader } from './components/ConfigHeader';
import { FormHelpContent } from './components/FormHelpContent';
import { Content } from './components/Content';

export const FieldConfigSidebar = () => {
  const { currentRoute } = useFormRouter();
  const { spektrFields, getCurrentField, deleteField } = useFormEngine();
  const currentField = getCurrentField();
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  if (currentRoute !== 'design') {
    return (
      <div
        className={cn(
          'h-full w-full',
          'border-color-border-secondary border-l',
          'bg-color-bg-secondary'
        )}
      >
        {currentField === null ? (
          <EmptyContent />
        ) : (
          <Accordion defaultValue={['content']} type="multiple">
            <div>
              <ConfigHeader
                title="Input"
                onDelete={() => deleteField(currentField.id)}
              />
              <Content
                currentField={currentField}
                spektrFields={spektrFields}
              />
            </div>
          </Accordion>
        )}
      </div>
    );
  }

  return (
    <div
      className={cn(
        'relative',
        'h-full w-full',
        'dark:border-color-border-secondary border-color-border-tertiary border-l',
        'bg-color-bg-secondary'
      )}
    >
      {currentField === null ? (
        <EmptyContent />
      ) : (
        <div ref={ref} className="absolute inset-0">
          <ScrollArea
            style={{
              height,
            }}
          >
            <Accordion defaultValue={['content']} type="multiple">
              <div>
                <ConfigHeader
                  title="Input"
                  onDelete={() => deleteField(currentField.id)}
                />
                <Content
                  currentField={currentField}
                  spektrFields={spektrFields}
                />
              </div>
            </Accordion>
          </ScrollArea>
        </div>
      )}
      <Accordion defaultValue="content" type="single">
        <FormHelpContent />
      </Accordion>
    </div>
  );
};
