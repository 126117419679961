import { RenderLeafProps } from 'slate-react';

import { SlateText } from '../types';

type LeafProps = RenderLeafProps & {
  leaf: SlateText;
};

export const Leaf = ({ attributes, children, leaf }: LeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};
