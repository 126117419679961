import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Input,
} from '@spektr/client/components';

import {
  RuleGroupBody,
  RuleGroupCard,
  RuleGroupHeader,
  RuleMenuComboBox,
  RuleGroupMenuComboBox,
} from '../ruleGroup';

import { AddInnerGroupButton } from '../buttons';

import { RuleGroupProvider, useIncompleteSegment } from '../providers';

import {
  SegmentWeightInput,
  SegmentTitleInput,
  TitleInput,
} from '../text-inputs';

import {
  RiskMatrixSegment,
  getSelectedFieldInMatrixRule,
  getUpdatedMatrixRuleGroups,
  isRiskMatrixSegment,
} from '../tree-helpers';

import { SegmentMenuComboBox, MatrixSegmentHeader } from './components';

export const Segment = () => {
  const {
    segment,
    config,
    spektrFields,
    updateRuleGroup,
    removeRuleGroup,
    removeSegment,
    addRuleGroup,
    updateSegment,
  } = useIncompleteSegment();

  if (!segment) return null;

  const isMatrixSegment = isRiskMatrixSegment(segment as RiskMatrixSegment);

  const handleSegmentMenuActionClick = (value: string) => {
    if (value === 'delete') removeSegment();
    else if (value === 'add-rule-group') addRuleGroup();
  };

  const handleFieldChange = (fieldValue: string) => {
    updateSegment({
      groups: getUpdatedMatrixRuleGroups(segment.groups, fieldValue),
    });
  };

  return (
    <Accordion
      key={segment.clientSideOnlyId}
      collapsible
      defaultValue={`segment-${segment.clientSideOnlyId}`}
      type="single"
      className="w-full overflow-y-auto"
    >
      <AccordionItem
        value={`segment-${segment.clientSideOnlyId}`}
        className="relative border-none"
      >
        <AccordionTrigger
          className="h-12 items-center justify-start bg-transparent px-3 pb-3 pt-2 hover:no-underline"
          iconPosition="left"
        >
          {isMatrixSegment && (
            <MatrixSegmentHeader
              selectedField={getSelectedFieldInMatrixRule(
                segment.groups[0]?.rule
              )}
              title={segment.title ?? ''}
              spektrFields={spektrFields}
              onFieldChange={handleFieldChange}
            />
          )}
        </AccordionTrigger>
        {!isMatrixSegment && (
          <div className="absolute left-6 top-2 flex justify-between">
            <SegmentTitleInput />
          </div>
        )}
        <div className="absolute right-3 top-2 flex items-center gap-2">
          {config.shouldDisplaySegmentScore && <SegmentWeightInput />}
          <SegmentMenuComboBox
            onActionClick={handleSegmentMenuActionClick}
            isMatrixSegment={isMatrixSegment}
          />
        </div>
        <AccordionContent>
          <div className="flex flex-col gap-4">
            {segment.groups.map((group) => (
              <RuleGroupProvider
                key={group.id ?? group.clientSideOnlyId}
                group={group}
                icon={(ruleId, innerGroupId) => (
                  <RuleMenuComboBox
                    className="mr-1"
                    ruleId={ruleId}
                    innerGroupId={innerGroupId}
                  />
                )}
                spektrFields={spektrFields}
                onChange={(updates) => {
                  if (updates) updateRuleGroup(group.clientSideOnlyId, updates);
                  else removeRuleGroup(group.clientSideOnlyId);
                }}
              >
                <RuleGroupCard>
                  <RuleGroupHeader>
                    <TitleInput
                      className="text-color-text-input hover:ring-spektr-zinc-600 focus:ring-spektr-zinc-600 pl-1 text-sm"
                      title={group.title ?? ''}
                      onChange={(title) =>
                        updateRuleGroup(group.clientSideOnlyId, { title })
                      }
                    />
                    <div className="flex items-center gap-3">
                      <p className="text-color-text-subtext text-xs font-normal">
                        Score
                      </p>
                      <Input
                        className="w-12 text-right"
                        type="number"
                        name="score"
                        dimension="small"
                        disabled={group.rule.type === 'matrix'}
                        value={group.score ?? '0'}
                        onChange={(e) =>
                          updateRuleGroup(group.clientSideOnlyId, {
                            score: e.target.value,
                          })
                        }
                      />
                      {group.rule.type !== 'matrix' && (
                        <RuleGroupMenuComboBox />
                      )}
                    </div>
                  </RuleGroupHeader>
                  <RuleGroupBody />
                </RuleGroupCard>
              </RuleGroupProvider>
            ))}
            {!isMatrixSegment && (
              <div className="flex justify-center pt-3">
                <AddInnerGroupButton />
              </div>
            )}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
