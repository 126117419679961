import { useRef } from 'react';
import { Info, Trash } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import { Button, Input, Tooltip } from '@spektr/client/components';

export type ChoiceFieldProps = {
  helperText?: string;
  label: string;
  name: string;
  options: string[];
  onAddOption: (name: string) => void;
  onChangeOption: (value: string, index: number) => void;
  onDeleteOption: (name: string) => void;
  onDefaultSelect?: (name: string) => void;
};

export const ChoiceField = ({
  helperText,
  label,
  name,
  options,
  onAddOption,
  onChangeOption,
  onDeleteOption,
}: ChoiceFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleAddOption = () => {
    if (inputRef.current) {
      onAddOption(inputRef.current.value);
      inputRef.current.value = '';
    }
  };

  return (
    <div className="flex flex-col gap-2 px-3">
      <div className="flex flex-row gap-2">
        <label
          className="text-color-text-subtext text-xs font-medium"
          htmlFor={name}
        >
          {label}
        </label>
        {helperText && (
          <Tooltip
            content={helperText}
            side="right"
            align="end"
            contentClassName="bg-color-bg-tooltip mb-1"
          >
            <Info className="h-3 w-3" />
          </Tooltip>
        )}
      </div>
      <div className={cn('flex flex-col gap-2')}>
        {options.map((option, index) => (
          <div key={option} className={cn('flex items-center gap-2')}>
            <Input
              inverted
              aria-label={option}
              className={cn('text-xs font-medium')}
              defaultValue={option}
              onBlur={(e) => onChangeOption(e.target.value, index)}
            />
            <button
              aria-label={`delete ${option}`}
              onClick={() => onDeleteOption(option)}
            >
              <Trash className="stroke-color-stroke-secondary flex h-3.5 w-3.5 shrink-0" />
            </button>
          </div>
        ))}
      </div>
      <div className="mt-2 flex flex-row gap-4">
        <Input
          aria-label="Add new option"
          name="new_option"
          ref={inputRef}
          inverted
          placeholder="New choice"
        />
        <Button name="add_option" onClick={handleAddOption}>
          Add
        </Button>
      </div>
    </div>
  );
};
