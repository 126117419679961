import { useCallback } from 'react';

import { useUpdateLoopNode } from '@spektr/shared/hooks';

import { MoonrakerForm } from '@spektr/moonraker-form';

import { updateActionFormNodeSchema } from '@spektr/shared/validators';
import { ActionFormNode, Process, SpektrField } from '@spektr/shared/types';
import { MoonrakerForm as MoonrakerFormData } from '@spektr/moonraker-types';

export type ActionFormDialogProps = {
  node: ActionFormNode;
  process: Process;
  spektrFields: SpektrField[];
  onClose: () => void;
};

export const ActionFormDialog = ({
  node,
  process,
  spektrFields,
  onClose,
}: ActionFormDialogProps) => {
  const updateMutation = useUpdateLoopNode(process.id);

  const handleUpdateNode = useCallback(
    async (data: MoonrakerFormData) => {
      const evaluated = updateActionFormNodeSchema.parse({
        nodeType: node.nodeType,
        form: node.form ?? [],
        title: node.title,
        moonrakerForm: data,
      });

      await updateMutation.mutateAsync({
        nodeId: node.id,
        node: evaluated,
      });

      onClose();
    },
    [updateMutation]
  );

  return (
    <MoonrakerForm
      isSavePending={updateMutation.isPending}
      node={node}
      process={process}
      spektrFields={spektrFields}
      onClose={onClose}
      onSave={handleUpdateNode}
    />
  );
};
