import { useState } from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { icons } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import type { Widget } from '../../../../types';

export type WidgetItemProps = {
  widget: Widget;
};

export const WidgetItem = ({ widget }: WidgetItemProps) => {
  const { t } = useTranslation('moonraker-form');
  const [isClickHolding, setIsClickHolding] = useState(false);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'widget',
    item: widget,
    end: () => {
      setIsClickHolding(false);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const Icon =
    icons[widget.icon as keyof typeof icons] ?? icons['TextCursorInput'];
  return (
    <button
      ref={drag}
      className={cn(
        'flex flex-row items-center gap-2 px-3',
        'h-10 w-full',
        'rounded-md',
        'bg-color-bg-button-primary hover:bg-color-bg-button-primary--hover',
        'text-color-text-button-contained-primary',
        'transition-colors duration-200',
        'cursor-grab',
        {
          'cursor-grabbing': isClickHolding,
          'bg-color-green': isDragging,
        }
      )}
      onMouseDown={() => setIsClickHolding(true)}
      onMouseUp={() => setIsClickHolding(false)}
    >
      <Icon className="h-4 w-4" />
      <span className="text-xs font-medium">{t(widget.title)}</span>
    </button>
  );
};
