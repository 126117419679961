import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { Cog, LayoutGrid, Mail } from 'lucide-react';

import {
  getNumberOfOutcomesForProcesses,
  getRootNode,
  processDetailsParamsSchema,
  loopDetailsUrl,
  shouldLoopHaveChannelSettings,
  getLoopSourcesFromRootNode,
} from '@spektr/shared/utils';

import {
  getAllowedSpektrFieldsQuery,
  getLoopByIdQuery,
  getProcessesQuery,
} from '@spektr/client/services';

import {
  useDialogClose,
  useParsedParams,
  useUpdateLoop,
} from '@spektr/shared/hooks';

import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Tabs,
  TabsList,
  TabsTrigger,
} from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';

import {
  LoopChannelSettings,
  LoopOutcomes,
} from '@spektr/model-builder/components';

import { NodeUpdateInput, LoopSource } from '@spektr/shared/types';
import { RBAC } from '@spektr/shared/rbac';
import {
  ChannelSettings,
  EmailSettings,
  SmsSettings,
} from '@spektr/shared/validators';

import { useFeatureFlags } from '@spektr/platform-hooks';

type SettingsTab = 'source' | 'channel';

type FormValues = {
  type: string;
  recipientField?: string;
  emailBody?: EmailSettings['emailBody'];
  smsBody?: SmsSettings['smsBody'];
};

// TODO: @Alex - Remove this mock data(ST-1735)
const MOCK_CHANNEL_SETTINGS = {
  type: 'email',
  mapping: { email: 'email' },
  emailBody: [
    {
      type: 'paragraph',
      children: [
        {
          text: 'They',
          italic: true,
        },
        {
          text: ' ',
        },
        {
          text: 'not',
          underline: true,
        },
        {
          text: ' like ',
        },
        {
          text: 'us',
          bold: true,
        },
        {
          text: '!',
        },
      ],
    },
  ],
};

export const UpdateLoopSourceDialog = () => {
  const { hasPermission } = usePermissionsContext();
  const { loopSettings } = useFeatureFlags();
  const navigate = useNavigate();
  const { processId } = useParsedParams(processDetailsParamsSchema);
  const [open, startExitAnimation] = useDialogClose();
  const [settingsTab, setSettingsTab] = useState<SettingsTab>('source');

  const { data: processes } = useSuspenseQuery(
    getProcessesQuery({
      types: ['risk', 'score', 'monitoring'],
    })
  );
  const { data: loop } = useSuspenseQuery(getLoopByIdQuery(processId));
  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, loop.rootId ?? '')
  );
  const [selectedSources, updateSelectedSources] = useState<LoopSource[]>(
    getLoopSourcesFromRootNode(loop)
  );
  // TODO: @Alex - Change this with actual api data(ST-1735)
  const channelSettings = MOCK_CHANNEL_SETTINGS as ChannelSettings;

  const formInstance = useForm<FormValues>({
    defaultValues: {
      type: channelSettings.type,
      recipientField: (channelSettings as any).mapping?.[channelSettings.type],
      emailBody: (channelSettings as EmailSettings).emailBody ?? [],
      smsBody: (channelSettings as SmsSettings).smsBody ?? '',
    },
  });

  const updateLoopMutation = useUpdateLoop(loop, () =>
    navigate(loopDetailsUrl(processId))
  );

  const handleClose = () => {
    navigate(loopDetailsUrl(processId));
  };

  const handleUpdateLoop = (newSources: LoopSource[]) => {
    const rootNode = loop ? getRootNode(loop) : undefined;
    const result = {
      nodeType: rootNode?.nodeType,
      sources: newSources,
    } as NodeUpdateInput;

    // TODO: @Alex - Perform channel settings update(ST-1735)
    console.log('form', formInstance.getValues());

    updateLoopMutation.mutate(result);
  };

  const numberOfOutcomes = getNumberOfOutcomesForProcesses(processes);
  const loopHasActionableNodes = loop && shouldLoopHaveChannelSettings(loop);
  const disableChannel = !loopSettings || !loopHasActionableNodes;

  return (
    <Dialog open={open} modal={false}>
      <DialogContent
        modal={false}
        className="bg-color-bg-dialog-default absolute flex flex-col"
        requestStartExitAnimation={startExitAnimation}
        onEndExitAnimation={handleClose}
        onEscapeKeyDown={startExitAnimation}
        data-cy="add-new-process-dialog"
      >
        <DialogHeader className="flex flex-col gap-4 space-y-0">
          <div className="flex items-center gap-2">
            <Cog className="h-4 w-4" />
            <DialogTitle className="text-color-text-dialog-title">
              Loop settings
            </DialogTitle>
          </div>
          <Tabs
            value={settingsTab}
            className="text-xs"
            onValueChange={(value: string) =>
              setSettingsTab(value as SettingsTab)
            }
          >
            <TabsList className="w-full">
              <TabsTrigger value="source" className="gap-1.5">
                <LayoutGrid className="h-4 w-4" /> Source
              </TabsTrigger>
              <TabsTrigger
                value="channel"
                className="gap-1.5"
                disabled={disableChannel}
              >
                <Mail className="h-4 w-4" /> Channel
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </DialogHeader>

        <DialogDescription className="my-4">
          {settingsTab === 'source'
            ? `Choose the loop source among the branches of existing processes. More
          than one branch can be selected as the loop source, this way a loop
          can be the resolution of multiple use cases.`
            : `Choose how your customers receive a notification to this loop. 
            Email or SMS will send the custom message you’ve define here. 
            The API channel will use the export defined in Settings.`}
        </DialogDescription>

        {settingsTab === 'source' ? (
          <LoopOutcomes
            key={numberOfOutcomes}
            loop={loop}
            processes={processes ?? []}
            onUpdateSelection={updateSelectedSources}
          />
        ) : (
          <FormProvider {...formInstance}>
            <LoopChannelSettings spektrFields={spektrFields} />
          </FormProvider>
        )}
        <DialogFooter>
          <Button
            disabled={
              !selectedSources.length ||
              !hasPermission(RBAC.ACTIONS.PROCESS.UPDATE) ||
              updateLoopMutation.isPending
            }
            color="red"
            className="mt-4"
            fullWidth
            onClick={() => handleUpdateLoop(selectedSources)}
          >
            Update loop
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
