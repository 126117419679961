import { z } from 'zod';

import { objectIdSchema } from '../common';

import { datasetFieldSchema } from './datasetField';
import {
  datasetMetaCreateSchema,
  datasetMetaExtendedSchema,
  datasetMetaSchema,
} from './datasetMeta';

export const datasetOrigins = ['csv', 'data-import'] as const;
export const datasetOriginSchema = z.enum(datasetOrigins);

export const datasetCreateSchema = z.strictObject({
  origin: datasetOriginSchema,
  key: z.string(),
  name: z.string(),
  recordCount: z.number(),
  createdBy: z.string().optional(),
});

export const datasetCreateInternalSchema = datasetCreateSchema.merge(
  z.strictObject({
    meta: datasetMetaCreateSchema,
    fields: z.array(datasetFieldSchema).length(0),
  })
);

export const datasetNameUpdateSchema = z.strictObject({
  name: z.string().min(1),
});

export const datasetFieldsUpdateSchema = z.strictObject({
  fieldId: z.string(),
  fields: z.array(datasetFieldSchema),
});

const datasetBaseSchema = z.object({
  origin: datasetOriginSchema,
  key: z.string(),
  name: z.string(),
  fieldId: z.string().optional(),
  fields: z.array(datasetFieldSchema),
  recordCount: z.number().optional(),
  createdBy: z.string().optional(),
  mappings: z.record(z.string()),
});

export const datasetWithMetaExtendedSchema = datasetBaseSchema.merge(
  z.object({
    meta: datasetMetaExtendedSchema,
  })
);

export const datasetSchema = datasetBaseSchema.merge(
  z.object({
    meta: datasetMetaSchema,
  })
);

export const datasetWithMetaExtendedResponseSchema =
  datasetWithMetaExtendedSchema.merge(
    z.strictObject({
      id: objectIdSchema,
      createdAt: z.number(),
      updatedAt: z.number(),
    })
  );

export const datasetResponseSchema = datasetSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    createdAt: z.number(),
    updatedAt: z.number(),
  })
);

export const datasetInputSchema = z.strictObject({
  streams: z.array(
    z.object({
      tap_stream_id: z.string(),
      schema: z.object({
        properties: z.record(
          z.string(),
          z.strictObject({ type: z.union([z.string(), z.array(z.string())]) })
        ),
      }),
    })
  ),
});
