import { createConfig } from '../utils/creators';

import type { Block } from '../types';

export const DEFAULT_BLOCKS: Block[] = [
  {
    id: 'individualInfo',
    category: 'kyc',
    icon: 'UserRoundCheck',
    title: 'blocks.individualInfo.title',
    description: 'blocks.individualInfo.description',
    components: [
      {
        id: 'name',
        type: 'input',
        config: createConfig('name'),
        attributes: {
          label: 'Name',
          type: 'text',
          placeholder: 'John Doe',
        },
        validation: [],
      },
      {
        id: 'nationality',
        type: 'select',
        config: createConfig('nationality'),
        attributes: {
          label: 'Nationality',
          options: [],
        },
        validation: [],
      },
      {
        id: 'address',
        type: 'input',
        config: createConfig('address'),
        attributes: {
          label: 'Address',
          type: 'text',
          placeholder: '1234 Main St',
        },
        validation: [],
      },
      {
        id: 'dateOfBirth',
        type: 'input',
        config: createConfig('dateOfBirth'),
        attributes: {
          label: 'Date of birth',
          type: 'date',
        },
        validation: [],
      },
      {
        id: 'phone',
        type: 'input',
        config: createConfig('phone'),
        attributes: {
          label: 'Phone',
          type: 'phone',
          placeholder: '+1234567890',
        },
        validation: [],
      },
      {
        id: 'email',
        type: 'input',
        config: createConfig('email'),
        attributes: {
          label: 'Email',
          type: 'email',
          placeholder: 'john@acme.ltd',
        },
        validation: [],
      },
      {
        id: 'taxId',
        type: 'input',
        config: createConfig('taxId'),
        attributes: {
          label: 'Tax ID',
          type: 'text',
          placeholder: '123-45-6789',
        },
        validation: [],
      },
      {
        id: 'govId',
        type: 'input',
        config: createConfig('govId'),
        attributes: {
          label: 'Government ID',
          type: 'text',
          placeholder: '123456789',
        },
        validation: [],
      },
    ],
  },
  {
    id: 'proofOfAddress',
    category: 'kyc',
    icon: 'Fence',
    title: 'blocks.proofOfAddress.title',
    description: 'blocks.proofOfAddress.description',
    components: [
      {
        id: 'bank_statement',
        type: 'file',
        config: createConfig('bank_statement'),
        attributes: {
          label: 'Bank statement',
          placeholder: 'Upload a bank statement',
          fileType: 'pdf',
        },
        validation: [],
      },
      {
        id: 'utility_statement',
        type: 'file',
        config: createConfig('utility_statement'),
        attributes: {
          label: 'Utility statement',
          placeholder: 'Upload a utility statement',
          fileType: 'pdf',
        },
        validation: [],
      },
    ],
  },
  {
    id: 'sourceOfFunds',
    category: 'kyc',
    icon: 'Landmark',
    title: 'blocks.sourceOfFunds.title',
    description: 'blocks.sourceOfFunds.description',
    components: [
      {
        id: 'bank_statement',
        type: 'file',
        config: createConfig('bank_statement'),
        attributes: {
          label: 'Bank statement',
          placeholder: 'Upload a bank statement',
          fileType: 'pdf',
        },
        validation: [],
      },
      {
        id: 'pay_stub',
        type: 'file',
        config: createConfig('pay_stub'),
        attributes: {
          label: 'Pay Stub Upload',
          placeholder: 'Upload a pay stub',
          fileType: 'pdf',
        },
        validation: [],
      },
    ],
  },
  {
    id: 'entityInformation',
    category: 'kyb',
    icon: 'Store',
    title: 'blocks.entityInformation.title',
    description: 'blocks.entityInformation.description',
    components: [
      {
        id: 'name',
        type: 'input',
        config: createConfig('name'),
        attributes: {
          label: 'Name',
          type: 'text',
          placeholder: 'John Doe',
        },
        validation: [],
      },
      {
        id: 'address',
        type: 'input',
        config: createConfig('address'),
        attributes: {
          label: 'Address',
          type: 'text',
          placeholder: '1234 Main St',
        },
        validation: [],
      },
      {
        id: 'dateOfIncorporation',
        type: 'input',
        config: createConfig('dateOfIncorporation'),
        attributes: {
          label: 'Date of Incorporation',
          type: 'date',
        },
        validation: [],
      },
      {
        id: 'registrationNumber',
        type: 'input',
        config: createConfig('registrationNumber'),
        attributes: {
          label: 'Company Registration Number',
          type: 'text',
          placeholder: '123-45-6789',
        },
        validation: [],
      },
      {
        id: 'vat',
        type: 'input',
        config: createConfig('vat'),
        attributes: {
          label: 'Tax/ VAT Number',
          type: 'text',
          placeholder: '123-45-6789',
        },
        validation: [],
      },
      {
        id: 'industry',
        type: 'select',
        config: createConfig('industry'),
        attributes: {
          label: 'Industry',
          options: [],
        },
        validation: [],
      },
    ],
  },
  {
    id: 'legalEntityVerification',
    category: 'kyb',
    icon: 'ShieldCheck',
    title: 'blocks.legalEntityVerification.title',
    description: 'blocks.legalEntityVerification.description',
    components: [
      {
        id: 'register_extract',
        type: 'file',
        config: createConfig('register_extract'),
        attributes: {
          label: 'Commercial Register Extract',
          placeholder: 'Upload a commercial register extract',
          fileType: 'pdf',
        },
        validation: [],
      },
      {
        id: 'vat_registration',
        type: 'file',
        config: createConfig('vat_registration'),
        attributes: {
          label: 'VAT Registration',
          placeholder: 'Upload a VAT registration',
          fileType: 'pdf',
        },
        validation: [],
      },
      {
        id: 'articles_of_incorporation',
        type: 'file',
        config: createConfig('articles_of_incorporation'),
        attributes: {
          label: 'Articles of Incorporation',
          placeholder: 'Upload a commercial register extract',
          fileType: 'pdf',
        },
        validation: [],
      },
      {
        id: 'tax_certificate',
        type: 'file',
        config: createConfig('tax_certificate'),
        attributes: {
          label: 'Tax Certificate',
          placeholder: 'Upload a tax certificate',
          fileType: 'pdf',
        },
        validation: [],
      },
      {
        id: 'certification_of_registration',
        type: 'file',
        config: createConfig('certification_of_registration'),
        attributes: {
          label: 'Certification of Registration',
          placeholder: 'Upload a certification of registration',
          fileType: 'pdf',
        },
        validation: [],
      },
    ],
  },
  {
    id: 'fetchingRepsUboPscs',
    category: 'kyb',
    icon: 'UsersRound',
    title: 'blocks.fetchingRepsUboPscs.title',
    description: 'blocks.fetchingRepsUboPscs.description',
    components: [
      {
        id: 'root',
        type: 'entity',
        config: {
          spektrDataField: 'entity_reps',
          prefillDefaultValue: false,
        },
        ui: {
          style: {
            width: '100%',
          },
        },
        attributes: {
          label: 'Entity representatives',
          buttonText: 'Add new entry',
        },
        validation: [],
        form: {
          fields: {
            entity_reps_name: {
              id: 'entity_reps_name',
              type: 'input',
              config: createConfig('entity_reps_name'),
              ui: {
                style: {
                  width: '100%',
                },
              },
              attributes: {
                label: 'Name',
                type: 'text',
                placeholder: 'Enter name',
              },
              validation: [],
            },
            entity_reps_email: {
              id: 'entity_reps_email',
              type: 'input',
              config: createConfig('entity_reps_email'),
              ui: {
                style: {
                  width: '100%',
                },
              },
              attributes: {
                label: 'Email',
                type: 'email',
                placeholder: 'Enter email',
              },
              validation: [],
            },
          },
          order: [],
        },
      },
    ],
  },
];
