import { useState } from 'react';
import { format } from 'date-fns';
import { CalendarDays } from 'lucide-react';

import { cn } from '@spektr/client/utils';
import { Button, Popover, Calendar } from '@spektr/client/components';

type DatePickerProps = {
  iconClassName?: string;
  className?: string;
  epochOrToday: string | number | '@today' | undefined;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (epochOrToday: string | '@today' | undefined) => void;
};

export const DatePicker = ({
  iconClassName,
  className,
  epochOrToday,
  disabled = false,
  placeholder = 'Pick a date',
  onChange,
}: DatePickerProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover
      className="w-auto"
      trigger={
        <Button
          disabled={disabled}
          variant="outlined"
          className={cn(
            'shrink-0 justify-start',
            'bg-color-bg-button-default hover:bg-color-bg-button-default--hover active:bg-color-bg-button-default--active',
            'border',
            'text-color-text-button-primary text-left text-sm font-normal',
            !epochOrToday && 'text-color-text-button-primary',
            className
          )}
        >
          <CalendarDays
            className={cn('mr-2 h-4 w-4 shrink-0', iconClassName)}
          />
          {epochOrToday === '@today' ? (
            <span className="shrink-0" children="@today" />
          ) : epochOrToday ? (
            format(new Date(Number(epochOrToday)), 'PPP')
          ) : (
            <span className="shrink-0">{placeholder}</span>
          )}
        </Button>
      }
      triggerProps={{
        asChild: true,
      }}
      open={open}
      onOpenChange={setOpen}
    >
      <div className="flex flex-col gap-1 pt-2">
        <Button
          variant="text"
          children="@today"
          className={cn(
            'border-color-border-button-secondary bg-color-bg-button-default ring-offset-background placeholder:text-color-text-placeholder disabled:bg-color-bg-accent disabled:text-color-text-button-primary/80 focus-visible:ring-ring text-color-text-button-primary hover:bg-color-bg-button-default--hover mx-auto h-auto w-fit rounded-md border-0 px-3 py-1 text-xs font-medium hover:opacity-100 focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:border-none disabled:opacity-50',
            epochOrToday === '@today' &&
              'bg-color-bg-accent/50 hover:bg-color-bg-button-default text-color-text-button-primary hover:text-color-text-button-primary focus:bg-color-bg-button-default--hover focus:text-color-text-button-primary '
          )}
          onClick={() => {
            onChange?.('@today');
            setOpen(false);
          }}
        />
        <Calendar
          mode="single"
          selected={
            epochOrToday && epochOrToday !== '@today'
              ? new Date(Number(epochOrToday))
              : undefined
          }
          onSelect={(date) => {
            onChange?.(date?.getTime()?.toString());
            setOpen(false);
          }}
          className="text-color-text-primary pt-0"
        />
      </div>
    </Popover>
  );
};
