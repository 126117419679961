import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { FullPageDialog } from '@spektr/client/components';

import { MoonrakerForm as MoonrakerFormType } from '@spektr/moonraker-types';
import { Process, ActionFormNode, SpektrField } from '@spektr/shared/types';

import { FormRouter } from './providers/FormRouter';
import { FormEngine } from './providers/FormEngine';
import { ViewpointsProvider } from './providers/ViewpointsProvider';

import { FormDialogHeader } from './containers/FormDialogHeader';
import { FormNavigation } from './containers/FormNavigation';
import { FormSidebar } from './containers/FormSidebar';

import { FormDialogContent } from './components/FormDialogContent';
import { FormCanvas } from './containers/FormCanvas';
import { FormHelpSidebar } from './containers/FormHelpSidebar';

export type MoonrakerFormProps = {
  isSavePending?: boolean;
  process: Process;
  node: ActionFormNode;
  spektrFields?: SpektrField[];
  onClose: () => void;
  onSave: (data: MoonrakerFormType) => void;
};

export const MoonrakerForm = ({
  isSavePending = false,
  process,
  node,
  spektrFields = [],
  onClose,
  onSave,
}: MoonrakerFormProps) => {
  return (
    <FullPageDialog className="h-full p-0" defaultOpen>
      <FormEngine
        initialForm={node?.moonrakerForm}
        spektrFields={spektrFields}
        onSave={onSave}
      >
        <ViewpointsProvider>
          <DndProvider backend={HTML5Backend}>
            <FormRouter>
              <div className="grid h-full w-full grid-rows-[48px_auto]">
                <FormDialogHeader
                  isSavePending={isSavePending}
                  processTitle={process.name}
                  nodeTitle={node.title}
                  onClickBack={onClose}
                />
                <FormDialogContent>
                  <FormNavigation />
                  <FormSidebar />
                  <FormHelpSidebar />
                  <FormCanvas />
                </FormDialogContent>
              </div>
            </FormRouter>
          </DndProvider>
        </ViewpointsProvider>
      </FormEngine>
    </FullPageDialog>
  );
};
