import { useMemo, useState } from 'react';
import { Info } from 'lucide-react';

import {
  Combobox,
  ComboboxGroup,
  ComboboxItem,
  ComboboxProps,
  SelectOption,
  Tooltip,
} from '@spektr/client/components';

export type ComboboxFieldProps = Omit<
  ComboboxProps,
  'onSearch' | 'children'
> & {
  name: string;
  label: string;
  helperText?: string;
  required?: boolean;
  options: SelectOption[];
  customOptions?: SelectOption[];
};

export const ComboboxField = ({
  name,
  label,
  helperText,
  options,
  customOptions,
  required,
  ...props
}: ComboboxFieldProps) => {
  const [searchValue, setSearchValue] = useState('');
  const filteredOptions = useMemo(
    () =>
      options.filter((field) =>
        field.label.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [searchValue, options]
  );
  const filteredCustomOptions = useMemo(
    () =>
      customOptions?.filter((field) =>
        field.label.toLowerCase().includes(searchValue.toLowerCase())
      ) ?? [],
    [searchValue, customOptions]
  );

  return (
    <div className="flex flex-col gap-2 px-3">
      <div className="flex flex-row gap-2">
        <label
          className="text-color-text-subtext text-xs font-medium"
          htmlFor={name}
        >
          {label}
          {required && <span className="text-color-red ml-1">*</span>}
        </label>
        {helperText && (
          <Tooltip
            content={helperText}
            side="right"
            align="end"
            contentClassName="bg-color-bg-tooltip/20 text-color-text-secondary mb-1"
          >
            <Info className="text-color-text-secondary/60 h-3 w-3" />
          </Tooltip>
        )}
      </div>
      <Combobox
        inverted
        {...props}
        onSearch={setSearchValue}
        noResults={
          filteredOptions.length === 0 && filteredCustomOptions.length === 0
        }
        noResultsText='No fields found. Press "Enter" to create a custom field with entered value.'
      >
        {filteredOptions.map(({ value, label }) => (
          <ComboboxItem inverted key={value} value={value}>
            {label}
          </ComboboxItem>
        ))}
        {filteredCustomOptions.length > 0 && (
          <ComboboxGroup label="Custom fields" headerClassName="bg-transparent">
            {filteredCustomOptions?.map(({ value, label }) => (
              <ComboboxItem inverted key={value} value={value}>
                {label}
              </ComboboxItem>
            ))}
          </ComboboxGroup>
        )}
      </Combobox>
    </div>
  );
};
