import { HTMLAttributes, ReactNode } from 'react';

import { cn } from '@spektr/client/utils';

export type PageDescriptionProps = HTMLAttributes<HTMLParagraphElement> & {
  children: ReactNode;
};

export const PageDescription = ({
  children,
  className,
  ...props
}: PageDescriptionProps) => (
  <p className={cn('text-base font-normal leading-6', className)} {...props}>
    {children}
  </p>
);
