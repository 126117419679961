import { queryOptions } from '@tanstack/react-query';

import { UsersApiClient } from './client';
import { getTeamMembersQueryKey } from './query.keys';

export function getTeamMembersQuery(customAttr?: string[]) {
  return queryOptions({
    queryKey: getTeamMembersQueryKey(customAttr),
    queryFn: () =>
      UsersApiClient.getClient().getUsers({
        queries: {
          customAttr: customAttr ? customAttr.join(',') : undefined,
        },
      }),
  });
}
