import { ProcessType } from '@spektr/shared/validators';
import { assertUnreachable } from '../utils';

export function getColorBasedOnProcessType(processType: ProcessType) {
  switch (processType) {
    case 'risk':
      return 'yellow';

    case 'score':
      return 'blue';

    case 'monitoring':
      return 'cyan';

    case 'loop':
      return 'red';

    default:
      return assertUnreachable(processType);
  }
}
