type FixedToolbarProps = {
  children: React.ReactNode;
};

export const FixedToolbar = ({ children }: FixedToolbarProps) => {
  return (
    <div className="mb-2 flex w-full items-center gap-1 border-b pb-1">
      {children}
    </div>
  );
};
