import { useState } from 'react';
import { MoreVertical } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import {
  DropdownMenuComboBox,
  SlateDescendant,
} from '@spektr/client/components';

import { useCommentActions } from '../../hooks/useCommentActions';
import { useUpdateComment } from '../../hooks/useUpdateComment';

import { EditCommentDialog } from '../EditCommentDialog';

type CommentActionsProps = {
  commentId: string;
  commentParentId?: string;
  commentMessage: SlateDescendant[];
  onDeleteComment?: () => void;
};

export const CommentActions = ({
  commentId,
  commentParentId,
  commentMessage,
  onDeleteComment,
}: CommentActionsProps) => {
  const [showEditCommentDialog, setShowEditCommentDialog] =
    useState<boolean>(false);
  const { actions, onActionClick } = useCommentActions(
    commentId,
    () => setShowEditCommentDialog(true),
    () => onDeleteComment?.(),
    commentParentId
  );
  const updateCommentMutation = useUpdateComment();

  const handleOnUpdate = async (message?: SlateDescendant[]) => {
    if (message) {
      await updateCommentMutation.mutateAsync({
        id: commentId,
        message: message,
      });
    }
    setShowEditCommentDialog(false);
  };

  return (
    <>
      <DropdownMenuComboBox
        align="end"
        side="bottom"
        trigger={
          <MoreVertical
            className={cn(
              'h-4 w-4',
              'stroke-color-stroke-default',
              'cursor-pointer',
              'hover:stroke-color-stroke-default--hover'
            )}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            data-cy="activity-item-header-more"
          />
        }
        options={actions}
        onClick={onActionClick}
      />

      {showEditCommentDialog && (
        <EditCommentDialog
          message={commentMessage}
          onCancel={() => setShowEditCommentDialog(false)}
          onResolve={(message) => handleOnUpdate(message)}
        />
      )}
    </>
  );
};
