import { icons } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import { IconButton } from '@spektr/client/components';

import type { Viewpoint } from '../../types/ViewpointsApi';

export type ViewpointItemProps = {
  name: Viewpoint;
  icon: keyof typeof icons;
  isActive?: boolean;
  onClick: (viewpoint: Viewpoint) => void;
};

export const ViewpointItem = ({
  name,
  icon,
  isActive = false,
  onClick,
}: ViewpointItemProps) => {
  const Icon = icons[icon];
  return (
    <IconButton
      className="flex h-8 w-8 shrink-0 p-0"
      onClick={() => onClick(name)}
    >
      <Icon
        className={cn('text-color-text-icon-secondary h-5 w-5', {
          'text-color-text-secondary': isActive,
        })}
      />
    </IconButton>
  );
};
