import { ArrowUpDown, SearchCode } from 'lucide-react';

import { NodeType } from '@spektr/shared/validators';

import {
  CalculatorIcon,
  IconWithBackground,
  RouterIcon,
} from '@spektr/client/components';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { LoopDrawerItem, ProcessDrawerItem } from '../types/DrawerItemType';

export const ProcessSteps = (): ProcessDrawerItem[] => {
  const { returningProcess, monitoringDataset } = useFeatureFlags();
  const steps: ProcessDrawerItem[] = [
    {
      processType: 'process',
      type: NodeType.enum.calculation,
      icon: (
        <IconWithBackground className="bg-color-cyan/10">
          <CalculatorIcon className="stroke-color-cyan h-6 w-6" />
        </IconWithBackground>
      ),
      title: 'Calculation step',
      variant: 'cyan' as const,
      description:
        'Create conditional rules and segment information into groups for later routing.',
    },
    {
      processType: 'process',
      type: NodeType.enum.router,
      icon: (
        <RouterIcon
          className="bg-color-red/10"
          iconClassName="h-6 w-6 stroke-color-red"
        />
      ),
      title: 'Routing step',
      variant: 'red' as const,
      description:
        'Create rules that take previous steps and routes to outcomes or branches.',
    },
    {
      processType: 'process',
      type: NodeType.enum.returningProcess,
      icon: (
        <IconWithBackground className="bg-color-cyan/10">
          <ArrowUpDown className="stroke-color-cyan h-6 w-6" />
        </IconWithBackground>
      ),
      title: 'Returning process step',
      variant: 'cyan' as const,
      description:
        'Calls another process to obtain its outcome and integrates the result into the current process.',
      hidden: !returningProcess,
    },
    {
      processType: 'process',
      type: NodeType.enum.monitoringDataset,
      icon: (
        <IconWithBackground className="bg-color-cyan/10">
          <SearchCode className="stroke-color-cyan h-6 w-6" />
        </IconWithBackground>
      ),
      title: 'Field watch',
      variant: 'cyan' as const,
      description:
        'This step monitors a specific field in the dataset and triggers the process if any changes occur.',
      hidden: !monitoringDataset,
    },
  ];

  return steps.filter((item) => !item.hidden);
};

export const useLoopSteps = (): LoopDrawerItem[] => {
  const { returningProcess, monitoringDataset } = useFeatureFlags();
  const steps: LoopDrawerItem[] = [
    {
      processType: 'loop',
      type: NodeType.enum.calculation,
      icon: (
        <IconWithBackground className="bg-color-cyan/10">
          <CalculatorIcon className="stroke-color-cyan h-6 w-6" />
        </IconWithBackground>
      ),
      title: 'Calculation step',
      variant: 'cyan' as const,
      description:
        'Create conditional rules and segment information into groups for later routing.',
    },
    {
      processType: 'loop',
      type: NodeType.enum.router,
      icon: (
        <RouterIcon
          className="bg-color-red/10"
          iconClassName="h-6 w-6 stroke-color-red"
        />
      ),
      title: 'Routing step',
      variant: 'red' as const,
      description:
        'Create rules that take previous steps and routes to outcomes or branches.',
    },
    {
      processType: 'loop',
      type: NodeType.enum.returningProcess,
      icon: (
        <IconWithBackground className="bg-color-cyan/10">
          <ArrowUpDown className="stroke-color-cyan h-6 w-6" />
        </IconWithBackground>
      ),
      title: 'Returning process step',
      variant: 'cyan' as const,
      description:
        'Calls another process to obtain its outcome and integrates the result into the current process.',
      hidden: !returningProcess,
    },
    {
      processType: 'loop',
      type: NodeType.enum.monitoringDataset,
      icon: (
        <IconWithBackground className="bg-color-cyan/10">
          <SearchCode className="stroke-color-cyan h-6 w-6" />
        </IconWithBackground>
      ),
      title: 'Field watch',
      variant: 'cyan' as const,
      description:
        'This step monitors a specific field in the dataset and triggers the process if any changes occur.',
      hidden: !monitoringDataset,
    },
  ];

  return steps.filter((item) => !item.hidden);
};
