import { ClipboardCopyIcon, ListTodoIcon } from 'lucide-react';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { useMemo } from 'react';

import { IconWithBackground } from '@spektr/client/components';

import { LoopDrawerItem } from '../types/DrawerItemType';

export const useLoopActions = (): LoopDrawerItem[] => {
  const { moonraker } = useFeatureFlags();

  return useMemo(() => {
    const actions: LoopDrawerItem[] = [
      {
        processType: 'loop',
        type: 'actionForm',
        title: 'Create a form',
        variant: 'red',
        icon: (
          <IconWithBackground className="bg-color-red">
            <ClipboardCopyIcon className="stroke-color-stroke-white h-6 w-6" />
          </IconWithBackground>
        ),
        description:
          'Create a shareable form and get the details you need to be compliant',
      },
      {
        processType: 'loop',
        type: 'action',
        title: 'Action',
        variant: 'action',
        icon: (
          <IconWithBackground className="bg-color-pink">
            <ListTodoIcon className="dark:stroke-color-stroke-primary stroke-color-stroke-default h-6 w-6" />
          </IconWithBackground>
        ),
        description:
          'The action step creates a manual task for you to resolve.',
      },
      {
        processType: 'loop',
        type: 'form',
        title: 'form (feature flagged)',
        variant: 'yellow',
        icon: (
          <IconWithBackground className="bg-color-yellow">
            <ClipboardCopyIcon className="stroke-color-stroke-white h-6 w-6" />
          </IconWithBackground>
        ),
        description: 'form-step description...',
        hidden: !moonraker,
      },
    ];

    return actions.filter((action) => !action.hidden);
  }, [moonraker]);
};
