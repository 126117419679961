import { CirclePlus } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import { Button, ButtonProps } from '@spektr/client/components';
import { useFeatureFlags } from '@spektr/platform-hooks';

type NodeDeleteButtonProps = ButtonProps & {
  iconClassName?: string;
};

export const NewFallbackButton = ({
  className,
  iconClassName,
  ...props
}: NodeDeleteButtonProps) => {
  const { moonraker } = useFeatureFlags();

  const hidden = !moonraker || process.env['NODE_ENV'] !== 'development';

  return (
    <div
      className={cn(
        'absolute bottom-0',
        'flex items-center justify-center',
        'w-full',
        'opacity-0 group-hover:opacity-100',
        'translate-y-full transform transition-opacity',
        hidden && 'hidden'
      )}
    >
      <Button
        className={cn(
          'bg-transparent hover:bg-transparent active:bg-transparent',
          'text-color-text-subtext hover:text-color-cyan disabled:text-color-text-subtext',
          'cursor-pointer disabled:cursor-not-allowed',
          'disabled:opacity-50',
          'transition-colors',
          className
        )}
        {...props}
      >
        <CirclePlus
          className={cn(
            'stroke-color-stroke-default bg-color-bg-primary hover:stroke-color-stroke-default--hover h-4 w-4 rounded-full',
            iconClassName
          )}
        />
      </Button>
    </div>
  );
};
