import { CalculatorIcon, IconWithBackground } from '@spektr/client/components';
import { cn } from '@spektr/client/utils';

export type CalculationNodeProps = {
  title: string;
};

export const CalculationNode = ({ title }: CalculationNodeProps) => {
  return (
    <>
      <div>
        <IconWithBackground
          className={cn('ml-5 mr-4 h-10 w-10', `bg-color-cyan`)}
        >
          <CalculatorIcon className="stroke-color-stroke-white h-4 w-4" />
        </IconWithBackground>
      </div>
      <div className="flex flex-col gap-0.5">
        <h3 className="text-color-text-node text-sm font-medium">{title}</h3>
        <span className="text-color-text-node-subtext text-xs">
          Calculation step
        </span>
      </div>
    </>
  );
};
