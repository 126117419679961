import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RBAC } from '@spektr/shared/rbac';

import { useDemo, usePermissionsContext } from '@spektr/client/providers';

import { useProcessScheduleContext } from '../../providers';

export const useGetPublishDisabledReason = (
  isLoop: boolean,
  hasChanges: boolean
) => {
  const { t } = useTranslation();
  const { t: tProcess } = useTranslation('process');
  const { isDemo } = useDemo();
  const { hasPermission } = usePermissionsContext();
  const { hasSource } = useProcessScheduleContext();

  return useMemo(() => {
    if (isDemo) {
      return t('errors.demoPlatform');
    }

    if (!isLoop) {
      if (!hasSource) {
        return tProcess('errors.publish.noSource');
      }
    }

    if (!hasPermission(RBAC.ACTIONS.PROCESS.LAUNCH)) {
      return tProcess('errors.permissions.publish');
    }

    if (!hasChanges) {
      return tProcess('errors.publish.noChanges');
    }

    return '';
  }, [hasSource, isLoop, hasChanges, hasPermission, isDemo, t, tProcess]);
};
