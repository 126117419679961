import { z } from 'zod';

import { InputField, InputFieldAttributes } from './InputField';

export const DateField = InputField.merge(
  z.object({
    attributes: InputFieldAttributes.merge(
      z.object({
        type: z.literal('date'),
        format: z.string().optional(),
      })
    ),
  })
);

export type DateField = z.infer<typeof DateField>;

export function isDateField(field: unknown): field is DateField {
  return DateField.safeParse(field).success;
}
