import { Outlet, useNavigate } from 'react-router-dom';
import { useContext } from 'react';

import {
  Button,
  PageHeadline,
  Tabbar,
  TabbarItem,
} from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';

import { LOGIN_URL } from '@spektr/shared/utils';
import { RBAC } from '@spektr/shared/rbac';

import { AuthContext } from '../../auth/auth-context';
import { HeaderWithHandler } from '../../components/HeaderWithHandler';

export function SettingsIndexPage() {
  const { logOut } = useContext(AuthContext);
  const { hasPermission } = usePermissionsContext();
  const navigate = useNavigate();

  const navigateToLogin = () =>
    navigate(LOGIN_URL, {
      state: {
        redirectFrom: null,
      },
    });

  const handleLogOut = () => logOut(navigateToLogin);

  return (
    <>
      <HeaderWithHandler />

      <div className="relative flex flex-grow flex-col overflow-hidden">
        <div className="px-5 pt-5">
          <div className="flex flex-row justify-between">
            <PageHeadline headline="Settings" paragraph="" />
            <Button color="red" onClick={handleLogOut}>
              Log out
            </Button>
          </div>
          <Tabbar>
            <TabbarItem to={{ pathname: '/settings' }}>Account</TabbarItem>
            <TabbarItem to={{ pathname: '/settings/workspace' }}>
              Workspace
            </TabbarItem>
            {hasPermission(RBAC.ACTIONS.API_KEYS.VIEW) && (
              <TabbarItem to={{ pathname: '/settings/developer' }}>
                Developer
              </TabbarItem>
            )}
          </Tabbar>
        </div>

        <Outlet />
      </div>
    </>
  );
}
