import { z } from 'zod';

import { FieldUiConfig } from './FieldConfig';

export const TitleField = z.object({
  id: z.string(),
  ui: FieldUiConfig,
  type: z.literal('title'),
  attributes: z.object({
    content: z.string(),
  }),
});

export type TitleField = z.infer<typeof TitleField>;

export const isTitleField = (field: unknown): field is TitleField => {
  return TitleField.safeParse(field).success;
};
