import { SpektrAIDetailsEnabled } from '@spektr/shared/validators';
import { TFunction } from 'i18next';

export const formatSpektrAIDetails = (
  spektrAIDetails: SpektrAIDetailsEnabled,
  t: TFunction,
  subject: string
) => {
  return {
    [t('fields:spektrAI.category')]: spektrAIDetails.category,
    [t('fields:spektrAI.reason')]: spektrAIDetails.reason,
    [t('fields:spektrAI.sameTarget', { subject })]: spektrAIDetails.sameTarget
      ? 'Yes'
      : 'No',
  };
};
