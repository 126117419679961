import { ArrowLeft } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import { ButtonWithTooltip, IconButton } from '@spektr/client/components';

import { useFormEngine } from '../../hooks/useFormEngine';
import { areCustomDataFieldsSet } from '../../utils/areCustomDataFieldsSet';

import { Title } from '../../components/Title';

import { Viewpoints } from '../Viewpoints';

export type FormDialogHeaderProps = {
  isSavePending?: boolean;
  processTitle: string;
  nodeTitle: string;
  onClick?: () => void;
  onClickBack?: () => void;
};

export const FormDialogHeader = ({
  isSavePending = false,
  processTitle,
  nodeTitle,
  onClickBack,
}: FormDialogHeaderProps) => {
  const { saveForm, state } = useFormEngine();

  const areCustomFieldsSet = areCustomDataFieldsSet(
    Object.values(state.fields)
  );

  return (
    <div
      className={cn(
        'p-1.5',
        'dark:border-color-border-secondary border-color-border-tertiary border-b',
        'bg-color-bg-secondary'
      )}
    >
      <div className="flex flex-row justify-between">
        <div className={cn('flex flex-row gap-3')}>
          <IconButton
            className={cn([
              'h-9 w-9',
              'p-0',
              'rounded-md',
              'bg-color-bg-form-navigation/50',
              'hover:bg-color-bg-form-navigation/80',
            ])}
            onClick={onClickBack}
          >
            <ArrowLeft className="h-4 w-4 shrink-0" />
            <span className="sr-only">Close</span>
          </IconButton>
          <Title processTitle={processTitle} nodeTitle={nodeTitle} />
        </div>
        <div className={cn('mt-0 flex flex-row items-center gap-6')}>
          <Viewpoints />
          <ButtonWithTooltip
            className={cn(
              'h-full',
              'text-color-text-button-secondary',
              'bg-color-bg-form-navigation',
              'hover:bg-color-bg-form-navigation/80',
              'transition-colors'
            )}
            disabled={isSavePending || !areCustomFieldsSet}
            showTooltip={!areCustomFieldsSet}
            tooltipProps={{
              content: 'Please fill out all required fields before saving.',
            }}
            onClick={saveForm}
          >
            {isSavePending ? 'Saving...' : 'Save form'}
            <span className="sr-only">Save</span>
          </ButtonWithTooltip>
        </div>
      </div>
    </div>
  );
};
