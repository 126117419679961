import { useState } from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { icons } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import type { Block } from '../../../../types';

export type BlockItemCardProps = {
  block: Block;
};

export const BlockItemCard = ({ block }: BlockItemCardProps) => {
  const { t } = useTranslation('moonraker-form');
  const [isClickHolding, setIsClickHolding] = useState(false);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'block',
    item: block,
    end: () => {
      setIsClickHolding(false);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const Icon = icons[block.icon as keyof typeof icons] ?? icons['BrickWall'];

  return (
    <button
      ref={drag}
      className={cn(
        'flex flex-row gap-3 text-left',
        'rounded-md p-4',
        'bg-color-bg-button-primary hover:bg-color-bg-button-primary--hover',
        'transition-colors duration-200',
        'cursor-grab',
        {
          'cursor-grabbing': isClickHolding,
          'bg-color-green': isDragging,
        }
      )}
    >
      <Icon
        className={cn(
          'flex shrink-0',
          'mt-1 h-4 w-4',
          'text-color-text-secondary'
        )}
      />
      <div className={cn('flex flex-col gap-1.5')}>
        <span className={cn('text-color-text-secondary text-base font-medium')}>
          {t(block.title)}
        </span>
        <span className={cn('text-sm', 'text-color-text-subtext')}>
          {t(block.description)}
        </span>
      </div>
    </button>
  );
};
