import { useRef, useEffect, ReactNode } from 'react';
import { Editor, Range } from 'slate';
import { useFocused, useSlate } from 'slate-react';

import { cn } from '@spektr/client/utils';

import { Portal } from './Portal';

type FloatingToolbarProps = {
  children: ReactNode;
};

export const FloatingToolbar = ({ children }: FloatingToolbarProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const editor = useSlate();
  const inFocus = useFocused();

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style');
      return;
    }

    const domSelection = window.getSelection();
    const domRange = domSelection?.getRangeAt(0);
    const rect = domRange?.getBoundingClientRect();

    if (!rect) return;

    el.style.opacity = '1';
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    el.style.left = `${
      rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
    }px`;
  });

  return (
    <Portal>
      <div
        className={cn(
          'flex items-center gap-1',
          'absolute left-[-10000px] top-[-10000px] z-50',
          'p-2',
          'bg-color-bg-primary text-color-text-editor rounded-md border'
        )}
        style={{
          padding: '8px 7px 6px',
          position: 'absolute',
          opacity: 0,
        }}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        ref={ref}
      >
        {children}
      </div>
    </Portal>
  );
};
