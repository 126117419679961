import { useCallback, useEffect, useMemo, useState } from 'react';

import { SpektrFieldTypedKey } from '@spektr/shared/validators';

import { Select } from '@spektr/client/components';

import { MatrixSegmentTitle } from './matrixSegmentTitle';

type MatrixSegmentHeaderProps = {
  selectedField?: string;
  title: string;
  onFieldChange: (value: string) => void;
  spektrFields: SpektrFieldTypedKey[];
};

export const MatrixSegmentHeader = ({
  selectedField = '',
  title,
  spektrFields,
  onFieldChange,
}: MatrixSegmentHeaderProps) => {
  const [selectValue, setSelectValue] = useState(selectedField);

  const fieldOptions = useMemo(
    () =>
      spektrFields?.map((field) => ({
        label: field.key,
        value: field.key,
      })) ?? [],
    [spektrFields]
  );

  const handleSelectValueChange = useCallback(
    (value: string) => {
      setSelectValue(value);
      onFieldChange(value);
    },
    [onFieldChange]
  );

  useEffect(() => {
    if (!selectValue && fieldOptions.length > 0) {
      handleSelectValueChange(fieldOptions[0].value);
    }
  }, [selectValue, fieldOptions, handleSelectValueChange]);

  return (
    <div className="flex items-center gap-9">
      <MatrixSegmentTitle>{title}</MatrixSegmentTitle>
      {spektrFields && (
        <Select
          className="max-w-[200px]"
          value={selectValue}
          size="small"
          options={fieldOptions}
          onValueChange={handleSelectValueChange}
          placeholder="Choose a field"
        />
      )}
    </div>
  );
};
