import { cva } from 'class-variance-authority';

import { cn } from '@spektr/client/utils';

import { memo } from 'react';

import { NodeServiceType } from '@spektr/shared/validators';
import {
  useLoopServices,
  useProcessServices,
} from 'libs/model-builder/containers/src/lib/ProcessSheet/config';

export type ServiceNodeProps = {
  serviceType: NodeServiceType;
};

export const ServiceNode = memo(({ serviceType }: ServiceNodeProps) => {
  const listOfServices = [
    ...useProcessServices('risk'),
    ...useProcessServices('monitoring'),
    ...useLoopServices(),
  ];
  const serviceItem = listOfServices.find((item) => item.type == serviceType);

  const baseColClass = cn(colVariants({ variant: serviceType }));

  return (
    <div className={cn(baseColClass, 'h-full w-full p-4')}>
      {serviceItem?.label && (
        <div
          className={cn(
            'absolute right-1 top-1',
            'bg-color-bg-primary/80 rounded px-2 py-0.5',
            'text-color-text-node text-[10px] font-medium uppercase tracking-wide'
          )}
        >
          {serviceItem?.label}
        </div>
      )}
      {serviceItem?.icon}
    </div>
  );
});

const colVariants = cva(
  [
    'flex items-center justify-center shrink-0',
    'rounded-lg',
    'bg-color-yellow',
    'text-color-text-node-subtext',
  ],
  {
    variants: {
      variant: {
        default: 'bg-color-bg-accent',
        complyAdvantageKyb: 'bg-color-complyAdvantage',
        complyAdvantageKyc: 'bg-color-complyAdvantage',
        openCorporatesMonitoring: 'bg-color-openCorporates',
        openCorporatesRisk: 'bg-color-openCorporates',
        bodacc: 'bg-color-bodacc',
        kyckr: 'bg-color-kyckr',
        veriff: 'bg-color-veriff',
        mitId: 'bg-color-mitId',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);
