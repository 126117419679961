import { Suspense } from 'react';

import { ProcessScheduleProvider } from '../../providers';

import { SettingItemSkeleton } from '../../components/SettingItemSkeleton';
import { VersionsSkeleton } from '../../components/VersionsSkeleton';
import { PublishVersion } from '../PublishVersion';
import { ProcessVersions } from '../ProcessVersions';

export const ProcessSchedule = ({ processId }: { processId: string }) => {
  return (
    <ProcessScheduleProvider processId={processId}>
      <div className="divide-color-border-primary flex flex-col">
        <Suspense fallback={<SettingItemSkeleton />}>
          <PublishVersion
            processId={processId}
            title="Publish a version"
            description="Make a new live version of the current process"
          />
        </Suspense>
      </div>

      <Suspense fallback={<VersionsSkeleton />}>
        <ProcessVersions processId={processId} />
      </Suspense>
    </ProcessScheduleProvider>
  );
};
