import { Cog } from 'lucide-react';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Handle, Position } from 'reactflow';

import { LOOPS_SOURCE_URL } from '@spektr/shared/utils';

import { LoopSourceNode as LoopSourceNodeType } from '@spektr/shared/types';

import { cn } from '@spektr/client/utils';

import { NODE } from '@spektr/shared/components';

import { GraphNodeProps } from './types';

export const LoopSourceNode = memo(
  ({ data }: GraphNodeProps<LoopSourceNodeType>) => {
    const navigate = useNavigate();

    const handleClick = () => {
      navigate(LOOPS_SOURCE_URL);
    };

    return (
      <div
        className={cn(
          'bg-color-bg-accent grid border border-solid shadow-lg duration-200 sm:rounded-lg',
          `h-[${NODE.HEIGHT}px] max-h-[90%] w-[${NODE.WIDTH}px]`,
          'flex min-h-0 flex-col gap-4',
          'hover:bg-color-bg-accent/70'
        )}
        onClick={handleClick}
      >
        <div
          className="flex h-full w-full flex-row items-center gap-6 p-6"
          onClick={handleClick}
        >
          <Cog className="stroke-color-stroke-default h-5 w-5" />
          <div className="flex flex-col gap-0.5">
            <h3 className="text-color-text-node text-sm font-medium">
              Loop settings
            </h3>
            <span className="text-color-text-node-subtext text-xs">{`${data.node.sources.length} sources`}</span>
          </div>
        </div>
        <Handle
          type="source"
          id="root"
          position={Position.Right}
          isConnectable={false}
          style={{ opacity: 0 }}
        />
      </div>
    );
  }
);
