import { InputHTMLAttributes } from 'react';
import { Info } from 'lucide-react';

import { Input, Tooltip } from '@spektr/client/components';

export type FormFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  helperText?: string;
};

export const FormField = ({ label, helperText, ...props }: FormFieldProps) => {
  return (
    <div className="flex flex-col gap-2 px-3">
      <div className="flex flex-row gap-2">
        <label
          className="text-color-text-subtext text-xs font-medium"
          htmlFor={props.name}
        >
          {label}
        </label>
        {helperText && (
          <Tooltip
            content={helperText}
            side="right"
            align="end"
            contentClassName="bg-color-bg-tooltip mb-1"
          >
            <Info className="h-3 w-3" />
          </Tooltip>
        )}
      </div>
      <Input id={props.name} {...props} inverted />
    </div>
  );
};
