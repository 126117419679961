import { useSuspenseQuery } from '@tanstack/react-query';
import { ArrowUpDown } from 'lucide-react';

import { RBAC } from '@spektr/shared/rbac';
import { NodeUpdateInput, Process } from '@spektr/shared/types';

import { usePermissionsContext } from '@spektr/client/providers';
import { cn } from '@spektr/client/utils';
import {
  Button,
  DialogClose,
  DialogContent,
  DialogContentProps,
  DialogDescription,
  DialogHeader,
  IconWithBackground,
  Label,
  Select,
} from '@spektr/client/components';
import { getProcessesQuery } from '@spektr/client/services';

import { useIncompleteReturningProcessNode } from './providers';

import { NodeDetailsDialogTitle } from './nodeDetailsDialogHeader';
import { TitleInput } from './text-inputs';

export type ReturningProcessNodeFormDialogContentProps = DialogContentProps & {
  onUpdate: (data: NodeUpdateInput) => void;
  isPendingUpdate: boolean;
  process: Process;
};

export const ReturningProcessNodeFormDialogContent = ({
  className,
  requestStartExitAnimation,
  onUpdate,
  isPendingUpdate,
  process,
  ...props
}: ReturningProcessNodeFormDialogContentProps) => {
  const { incomplete, returningProcessNode, setTitle, setProcessId } =
    useIncompleteReturningProcessNode();

  const { hasPermission } = usePermissionsContext();

  const { data: processList } = useSuspenseQuery(
    getProcessesQuery({
      types: ['risk', 'monitoring', 'score'],
    })
  );

  return (
    <DialogContent
      modal={false}
      size="large"
      className={cn('flex min-h-fit flex-col', className)}
      requestStartExitAnimation={requestStartExitAnimation}
      {...props}
    >
      <DialogHeader className="space-y-0">
        <NodeDetailsDialogTitle
          left={
            <div className="flex items-center gap-2">
              <IconWithBackground className="bg-color-cyan p-1">
                <ArrowUpDown className="stroke-color-stroke-white h-4 w-4" />
              </IconWithBackground>
              <TitleInput title={incomplete.title} onChange={setTitle} />
            </div>
          }
          right={
            <div className="flex items-center gap-2">
              <Button
                color="cyan"
                type="button"
                size="sm"
                disabled={
                  !returningProcessNode.success ||
                  !incomplete.processId ||
                  isPendingUpdate ||
                  !hasPermission(RBAC.ACTIONS.NODE.UPDATE)
                }
                onClick={() => {
                  if (!returningProcessNode.success) return;
                  onUpdate(returningProcessNode.data);
                }}
              >
                {isPendingUpdate ? 'Saving...' : 'Save'}
              </Button>
              <DialogClose onClick={requestStartExitAnimation} />
            </div>
          }
        />
      </DialogHeader>
      <DialogDescription className="mb-6">
        Call another process, retrieving its outcome so you can use it within
        the current process. This allows for building rules on the the other
        process result and use it for better decision-making operations.
      </DialogDescription>
      <div className="flex items-end justify-between gap-4">
        <div className="flex flex-col gap-1">
          <Label>Choose which process from the dropdown:</Label>
          <Select
            className="w-72"
            required
            name="process"
            placeholder="Select process"
            options={processList.map((p) => ({
              value: p.id,
              label: p.name,
              disabled: p.id === process.id,
            }))}
            value={incomplete.processId}
            onValueChange={setProcessId}
          />
        </div>
      </div>
    </DialogContent>
  );
};
