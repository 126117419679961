import { useTheme } from '@spektr/client/providers';

import { Label, Switch } from '../forms';

export const ThemeToggle = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <div className="flex items-center gap-4">
      <Switch
        defaultChecked={theme === 'light'}
        onCheckedChange={toggleTheme}
      />
      <Label className="text-sm">Toggle light mode</Label>
    </div>
  );
};
