import { z } from 'zod';

import {
  BrandingFormAssistingContent,
  BrandingFormTheme,
} from '@spektr/branding-types';

import { Field } from './Field';

// TODO: (@Robert) - Add layout schema
export const MoonrakerForm = z.object({
  fields: z.record(z.string(), Field),
  order: z.array(z.string()),
  branding: BrandingFormTheme.optional(),
  assistingContent: BrandingFormAssistingContent.optional(),
});

export type MoonrakerForm = z.infer<typeof MoonrakerForm>;
