import { cn } from '@spektr/client/utils';

import { useFormRouter } from '../../hooks/useFormRouter';
import { useFormEngine } from '../../hooks/useFormEngine';

import { PageTitle } from './components/PageTitle';
import { PageDescription } from './components/PageDescription';
import { PageHelpPopup } from './components/PageHelpPopup';

export const FormHelpSidebar = () => {
  const { currentRoute } = useFormRouter();
  const { state } = useFormEngine();

  if (currentRoute !== 'design') {
    return null;
  }

  return (
    <div
      className={cn(
        'flex grow flex-col',
        'p-6',
        'm-3',
        'box-border',
        'shrink-0',
        'rounded-3xl',
        'shadow-sm transition-all'
      )}
      style={{
        backgroundColor: state.branding.backgroundColor,
      }}
    >
      <div className="flex flex-col p-6">
        {state.branding.logoSrc && (
          <img src={state.branding.logoSrc} alt="Logo" className="w-fit" />
        )}
        <div className="mt-10">
          <PageTitle
            style={{
              color: state.branding.textColor,
            }}
          >
            {state.assistingContent.title}
          </PageTitle>
        </div>
        <div className="mt-5 w-[307px]">
          <PageDescription style={{ color: state.branding.subTextColor }}>
            {state.assistingContent.description}
          </PageDescription>
        </div>
      </div>

      {state.assistingContent.support.display && (
        <div className="mt-auto">
          <PageHelpPopup
            title={state.assistingContent.support.title}
            text={state.assistingContent.support.content}
            style={{
              color: state.assistingContent.support.textColor,
              backgroundColor: state.assistingContent.support.backgroundColor,
            }}
          />
        </div>
      )}
    </div>
  );
};
