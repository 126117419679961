import { useSlate } from 'slate-react';
import { icons } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import { Button } from '../../Button';
import { isMarkActive, toggleMark } from '../utils';
import { InlineFormats } from '../types';

type FormatButtonProps = {
  format: InlineFormats;
  iconName: keyof typeof icons;
};

export const FormatButton = ({ format, iconName }: FormatButtonProps) => {
  const editor = useSlate();

  const Icon = icons[iconName];

  return (
    <Button
      type="button"
      variant="text"
      size="sm"
      className={cn(
        'hover:bg-color-bg-button-secondary--hover',
        isMarkActive(editor, format) &&
          'bg-color-bg-button-secondary--active/50'
      )}
      onClick={() => toggleMark(editor, format)}
    >
      <Icon className="h-3 w-3" />
    </Button>
  );
};
