import { useMemo } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Badge, ButtonWithTooltip, Spinner } from '@spektr/client/components';
import { useDemo, usePermissionsContext } from '@spektr/client/providers';
import { Card } from '@spektr/shared/components';

import { RBAC } from '@spektr/shared/rbac';
import {
  LiveProcessVersion,
  ProcessVersionOverviewResponse,
} from '@spektr/shared/types';

import { useProcessScheduleContext } from '../../providers';

export type VersionsItemProps = {
  isLiveActionPending?: boolean;
  isRunActionPending?: boolean;
  overview: ProcessVersionOverviewResponse;
  liveVersion?: LiveProcessVersion;
  onClickGoLive: (versionId: string) => void;
  onClickStopLive: () => void;
  onClickRunNow: () => void;
};

export const VersionsItem = ({
  isLiveActionPending = false,
  isRunActionPending = false,
  overview,
  liveVersion,
  onClickGoLive,
  onClickStopLive,
  onClickRunNow,
}: VersionsItemProps) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissionsContext();
  const { isDemo } = useDemo();
  const { isRiskProcess } = useProcessScheduleContext();

  const versions = useMemo(() => {
    if (overview.versions.length === 0) {
      return [];
    }

    return overview.versions.sort((a, b) => b.versionNumber - a.versionNumber);
  }, [overview.versions]);

  const actionDisabledReason = useMemo(() => {
    if (isDemo) {
      return t('errors.demoPlatform');
    }

    if (!hasPermission(RBAC.ACTIONS.PROCESS.LAUNCH)) {
      return t('errors.notAllowed');
    }

    return undefined;
  }, [hasPermission, isDemo, t]);

  if (versions.length === 0) {
    return (
      <Card className="flex max-h-[200px] flex-col gap-2 overflow-y-auto p-4">
        <div className="flex items-center justify-center">
          <div className="flex items-center">
            <span className="text-color-text-subtext text-sm">
              See your created version here, that you can set to live.
            </span>
          </div>
        </div>
      </Card>
    );
  }

  const isActionPending = isLiveActionPending || isRunActionPending;
  const isActionDisabled = isActionPending || !!actionDisabledReason;

  return (
    <Card className="flex max-h-[200px] flex-col gap-8 overflow-y-auto p-4">
      {versions.map((version) => (
        <div
          key={version.versionNumber}
          className="flex h-9 shrink-0 items-center justify-between"
        >
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-2">
              <p className="text-color-text-primary text-sm">{`Version ${version.versionNumber}`}</p>
              {liveVersion?.versionId === version.id && (
                <Badge size="sm" variant="cyan">
                  Live
                </Badge>
              )}
            </div>
            <p className="text-color-text-subtext text-xs">
              {format(version.publishedAt, 'PPP HH:mm')}
            </p>
          </div>

          {liveVersion?.versionId === version.id ? (
            <div className="flex items-center gap-2">
              {isRiskProcess && (
                <ButtonWithTooltip
                  color="cyan"
                  variant="outlined"
                  size="sm"
                  disabled={isActionDisabled}
                  showTooltip={!!actionDisabledReason}
                  tooltipProps={{
                    content: actionDisabledReason,
                  }}
                  onClick={onClickRunNow}
                >
                  <div className="flex gap-2">
                    {isRunActionPending && <Spinner size="sm" />}
                    <span>Run now</span>
                  </div>
                </ButtonWithTooltip>
              )}
              <ButtonWithTooltip
                color="red"
                size="sm"
                disabled={isActionDisabled}
                showTooltip={!!actionDisabledReason}
                tooltipProps={{
                  content: actionDisabledReason,
                }}
                onClick={onClickStopLive}
              >
                <div className="flex gap-2">
                  {isLiveActionPending && <Spinner size="sm" />}
                  <span>Stop</span>
                </div>
              </ButtonWithTooltip>
            </div>
          ) : overview?.latestVersionId === version.id ? (
            <ButtonWithTooltip
              color="cyan"
              size="sm"
              disabled={isActionDisabled}
              showTooltip={!!actionDisabledReason}
              tooltipProps={{
                content: actionDisabledReason,
              }}
              onClick={() => onClickGoLive(version.id)}
            >
              <div className="flex gap-2">
                {isLiveActionPending && <Spinner size="sm" />}
                <span>Go Live</span>
              </div>
            </ButtonWithTooltip>
          ) : null}
        </div>
      ))}
    </Card>
  );
};
