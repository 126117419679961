import { ReactNode } from 'react';

export const appNavColorVariants = {
  home: {
    color: 'bg-color-bg-nav-link--active text-color-text-navbar-home--active',
    hover:
      'hover:bg-color-bg-nav-link--hover hover:text-color-text-navbar--hover',
  },
  connectionHub: {
    color: 'bg-color-yellow/20 text-color-text-navbar-connection-hub--active',
    hover:
      'hover:bg-color-yellow/20 hover:text-color-text-navbar-connection-hub--hover',
  },
  processes: {
    color: 'bg-color-cyan/20 text-color-text-navbar-processes--active',
    hover:
      'hover:bg-color-cyan/20 hover:text-color-text-navbar-processes--hover',
  },
  loops: {
    color: 'bg-color-red/20 text-color-text-navbar-loops--active',
    hover: 'hover:bg-color-red/20 hover:text-color-text-navbar-loops--hover',
  },
  insights: {
    color: 'bg-color-pink/20 text-color-text-navbar-insights--active',
    hover:
      'hover:bg-color-pink/20 hover:text-color-text-navbar-insights--hover',
  },
  strategy: {
    color:
      'bg-color-bg-nav-link--active text-color-text-navbar-strategy--active',
    hover:
      'hover:bg-color-bg-nav-link--hover hover:text-color-text-navbar-strategy--hover',
  },
  settings: {
    color:
      'bg-color-bg-nav-link--active text-color-text-navbar-settings--active',
    hover:
      'hover:bg-color-bg-nav-link--hover hover:text-color-text-navbar-settings--hover',
  },
};

export type AppNavColorVariant = keyof typeof appNavColorVariants;

export type AppNavItem = {
  id: string;
  label: string;
  link: string;
  icon: ReactNode;
  color: AppNavColorVariant;
  released?: boolean;
  disabled?: boolean;
  count?: number;
};
