import { Info } from 'lucide-react';

import { Tooltip, Switch, SwitchProps } from '@spektr/client/components';

export type SwitchFieldProps = SwitchProps & {
  label: string;
  helperText?: string;
};

export const SwitchField = ({
  label,
  helperText,
  ...props
}: SwitchFieldProps) => {
  return (
    <div className="flex flex-row items-center justify-between px-3">
      <div className="flex flex-row gap-2">
        <label
          className="text-color-text-subtext text-xs font-medium"
          htmlFor={props.name}
        >
          {label}
        </label>
        {helperText && (
          <Tooltip
            content={helperText}
            side="right"
            align="end"
            contentClassName="bg-color-bg-tooltip mb-1"
          >
            <Info className="h-3 w-3" />
          </Tooltip>
        )}
      </div>
      <Switch inverted {...props} />
    </div>
  );
};
