import { Role } from '@spektr/shared/types';

const ACTIONS = {
  DATASET: {
    VIEW: 'dataset_view',
    CREATE: 'dataset_create',
    UPDATE: 'dataset_update',
    DELETE: 'dataset_delete',
  },
  PROCESS: {
    VIEW: 'process_view',
    CREATE: 'process_create',
    UPDATE: 'process_update',
    DELETE: 'process_delete',
    EXECUTE: 'process_execute',
    LAUNCH: 'process_launch',
  },
  RISK_MATRIX: {
    VIEW: 'matrix_view',
    CREATE: 'matrix_create',
    UPDATE: 'matrix_update',
    DELETE: 'matrix_delete',
  },
  RISK_MATRIX_TEMPLATE: {
    VIEW: 'matrix_template_view',
    CREATE: 'matrix_template_create',
  },
  NODE: {
    VIEW: 'node_view',
    CREATE: 'node_create',
    UPDATE: 'node_update',
    DELETE: 'node_delete',
  },
  USER: {
    VIEW: 'user_view',
    UPDATE: 'user_update',
    DELETE: 'user_delete',
    INVITE: 'user_invite',
  },
  API_KEYS: {
    VIEW: 'api_keys_view',
    CREATE: 'api_keys_create',
    DELETE: 'api_keys_delete',
  },
  USER_SETTINGS: {
    VIEW: 'user_settings_view',
    SET: 'user_settings_set',
  },
};

const PERMISSIONS = {
  // DATASET
  [ACTIONS.DATASET.VIEW]: ['viewer', 'demo'],
  [ACTIONS.DATASET.CREATE]: ['editor'],
  [ACTIONS.DATASET.UPDATE]: ['editor'],
  [ACTIONS.DATASET.DELETE]: ['editor'],
  // PROCESS
  [ACTIONS.PROCESS.VIEW]: ['viewer', 'demo'],
  [ACTIONS.PROCESS.CREATE]: ['editor', 'demo'],
  [ACTIONS.PROCESS.UPDATE]: ['editor', 'demo'],
  [ACTIONS.PROCESS.DELETE]: ['editor', 'demo'],
  [ACTIONS.PROCESS.LAUNCH]: ['editor'],
  // RISK MATRIX
  [ACTIONS.RISK_MATRIX.VIEW]: ['viewer', 'demo'],
  [ACTIONS.RISK_MATRIX.CREATE]: ['editor'],
  [ACTIONS.RISK_MATRIX.UPDATE]: ['editor'],
  [ACTIONS.RISK_MATRIX.DELETE]: ['editor'],
  // RISK MATRIX TEMPLATE
  [ACTIONS.RISK_MATRIX_TEMPLATE.VIEW]: ['editor', 'demo'],
  [ACTIONS.RISK_MATRIX_TEMPLATE.CREATE]: ['editor', 'demo'],
  // NODE
  [ACTIONS.NODE.VIEW]: ['viewer', 'demo'],
  [ACTIONS.NODE.CREATE]: ['editor', 'demo'],
  [ACTIONS.NODE.UPDATE]: ['editor', 'demo'],
  [ACTIONS.NODE.DELETE]: ['editor', 'demo'],
  // USER
  [ACTIONS.USER.VIEW]: ['viewer', 'demo'],
  [ACTIONS.USER.UPDATE]: ['admin'],
  [ACTIONS.USER.DELETE]: ['admin'],
  [ACTIONS.USER.INVITE]: ['admin', 'demo'],
  // API KEYS
  [ACTIONS.API_KEYS.VIEW]: ['viewer'],
  [ACTIONS.API_KEYS.CREATE]: ['admin'],
  [ACTIONS.API_KEYS.DELETE]: ['admin'],
  // USER SETTINGS
  [ACTIONS.USER_SETTINGS.VIEW]: ['viewer'],
  [ACTIONS.USER_SETTINGS.SET]: ['editor'],
};

const hasPermission = (role: Role, action: string): boolean => {
  if (role === 'admin') {
    return true;
  }

  if (PERMISSIONS[action].includes(role)) {
    return true;
  }

  if (role === 'editor' && PERMISSIONS[action].includes('viewer')) {
    return true;
  }

  return false;
};

export default {
  ACTIONS,
  hasPermission,
};
