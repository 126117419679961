import z from 'zod';

export const SortOrder = z.enum(['asc', 'desc']);
export type SortOrder = z.infer<typeof SortOrder>;

export const FilterOptions = z
  .object({
    sortOrder: SortOrder.optional(),
    sortField: z.string().optional(),
    searchField: z.string().optional(),
    searchValue: z.coerce.string().optional(),
  })
  .superRefine((val, ctx) => {
    if (val.searchValue && !val.searchField) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'searchField is required when searchValue is defined',
        path: ['searchField'],
      });
    }
  });
export type FilterOptions = z.infer<typeof FilterOptions>;
