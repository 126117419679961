import { z } from 'zod';

export const datasetScanStates = [
  'idle',
  'processing',
  'done',
  'failed',
] as const;
export const datasetScanStateSchema = z.enum(datasetScanStates);

export const datasetMetaCreateSchema = z.strictObject({
  scan: datasetScanStateSchema,
  error: z.string().optional(),
});

export const datasetMetaUpdateSchema = z.strictObject({
  scan: datasetScanStateSchema.optional(),
  error: z.string().optional(),
});

export const datasetMetaSchema = datasetMetaCreateSchema;

export const datasetMetaExtendedSchema = datasetMetaCreateSchema.merge(
  z.strictObject({
    isEditable: z.boolean(),
    linkedProcessIds: z.array(z.string()),
  })
);
