import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { Content } from '@radix-ui/react-tooltip';

import { cn } from '@spektr/client/utils';

import './styles.css';

export type TooltipContentProps = ComponentPropsWithoutRef<typeof Content>;

export const TooltipContent = forwardRef<
  ElementRef<typeof Content>,
  TooltipContentProps
>(({ className, sideOffset = 4, ...props }, ref) => (
  <Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'border-color-bg-tooltip-content/30 rounded-md border dark:border-none',
      'max-w-xs px-3 py-2',
      'bg-color-bg-tooltip-content bg-opacity-90',
      'text-color-text-primary text-xs',
      'z-50 overflow-hidden shadow-2xl',
      'animate-in fade-in-0 zoom-in-95',
      'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95',
      'content',
      className
    )}
    {...props}
  />
));
