import { basicLogger } from 'launchdarkly-react-client-sdk';

import {
  LAUNCH_DARKLY_CLIENT_SIDE_ID,
  LAUNCH_DARKLY_LOG_LEVEL,
} from './network';

export default {
  clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_ID,
  options: {
    logger: basicLogger({ level: LAUNCH_DARKLY_LOG_LEVEL }),
  },
  reactOptions: {
    useCamelCaseFlagKeys: true,
  },
  // Overwrite flags for local testing
  // flags: {
  // moonraker: false,
  // 'kycr-and-caoc': true
  // 'returning-process': true
  // 'monitoring-dataset': true
  // 'spektrTheme': true
  // },
};
