import { createUiConfig, createConfig } from '../utils/creators';

import type { Widget } from '../types';

export const DEFAULT_WIDGETS: Widget[] = [
  {
    id: 'vatNumber',
    category: 'companyInformation',
    title: 'fields.vatNumber',
    icon: 'Binary',
    component: {
      id: 'vatNumber',
      type: 'input',
      config: createConfig('vat_number'),
      ui: createUiConfig(),
      attributes: {
        label: 'VAT Number',
        type: 'text',
        placeholder: 'IE1234567T',
      },
      validation: [],
    },
  },
  {
    id: 'companyNumber',
    category: 'companyInformation',
    title: 'fields.companyNumber',
    icon: 'Binary',
    component: {
      id: 'companyNumber',
      type: 'input',
      config: createConfig('company_number'),
      ui: createUiConfig(),
      attributes: {
        label: 'Company Number',
        type: 'text',
        placeholder: 'IE1234567T',
      },
      validation: [],
    },
  },
  {
    id: 'countryCode',
    category: 'companyInformation',
    title: 'fields.countryCode',
    icon: 'TextCursorInput',
    component: {
      id: 'countryCode',
      type: 'input',
      config: createConfig('country_code'),
      ui: createUiConfig(),
      attributes: {
        label: 'Country Code',
        type: 'text',
        placeholder: 'dk',
      },
      validation: [],
    },
  },
  {
    id: 'incorporationDate',
    category: 'companyInformation',
    title: 'fields.incorporationDate',
    icon: 'CalendarPlus2',
    component: {
      id: 'incorporationDate',
      type: 'input',
      config: createConfig('incorporation_date'),
      ui: createUiConfig(),
      attributes: {
        label: 'Incorporation Date',
        type: 'date',
        placeholder: '01/01/2000',
      },
      validation: [],
    },
  },
  {
    id: 'naceCode',
    category: 'companyInformation',
    title: 'fields.naceCode',
    icon: 'List',
    component: {
      id: 'naceCode',
      type: 'select',
      config: createConfig('nace_code'),
      ui: createUiConfig(),
      attributes: {
        label: 'NACE code',
        placeholder: 'Select NACE code',
        options: [], // these will be overwritten lazily by createFieldFromWidget
      },
      validation: [],
    },
  },
  {
    id: 'firstName',
    category: 'pii',
    title: 'fields.firstName',
    icon: 'TextCursorInput',
    component: {
      id: 'firstName',
      type: 'input',
      config: createConfig('first_name'),
      ui: createUiConfig(),
      attributes: {
        label: 'First Name',
        type: 'text',
        placeholder: 'John',
      },
      validation: [],
    },
  },
  {
    id: 'middleName',
    category: 'pii',
    title: 'fields.middleName',
    icon: 'TextCursorInput',
    component: {
      id: 'middleName',
      type: 'input',
      config: createConfig('middle_name'),
      ui: createUiConfig(),
      attributes: {
        label: 'Middle name',
        type: 'text',
        placeholder: 'Middle',
      },
      validation: [],
    },
  },
  {
    id: 'lastName',
    category: 'pii',
    title: 'fields.lastName',
    icon: 'TextCursorInput',
    component: {
      id: 'lastName',
      type: 'input',
      config: createConfig('last_name'),
      ui: createUiConfig(),
      attributes: {
        label: 'Last name',
        type: 'text',
        placeholder: 'Doe',
      },
      validation: [],
    },
  },
  {
    id: 'fullName',
    category: 'pii',
    title: 'fields.fullName',
    icon: 'TextCursorInput',
    component: {
      id: 'fullName',
      type: 'input',
      config: createConfig('full_name'),
      ui: createUiConfig(),
      attributes: {
        label: 'Full name',
        type: 'text',
        placeholder: 'John Doe',
      },
      validation: [],
    },
  },
  {
    id: 'companyName',
    category: 'companyInformation',
    title: 'fields.companyName',
    icon: 'TextCursorInput',
    component: {
      id: 'companyName',
      type: 'input',
      config: createConfig('company_name'),
      ui: createUiConfig(),
      attributes: {
        label: 'Company name',
        type: 'text',
        placeholder: 'Acme Inc.',
      },
      validation: [],
    },
  },
  {
    id: 'dateOfBirth',
    category: 'pii',
    title: 'fields.dateOfBirth',
    icon: 'CalendarPlus2',
    component: {
      id: 'dateOfBirth',
      type: 'input',
      config: createConfig('date_of_birth'),
      ui: createUiConfig(),
      attributes: {
        label: 'Date Of Birth',
        type: 'text',
        placeholder: '01/01/2000',
      },
      validation: [],
    },
  },
];
