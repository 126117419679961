import { RenderElementProps } from 'slate-react';

import { SlateElement } from '../types';

import { Mention } from './Mention';

export const Element = ({
  element,
  attributes,
  children,
}: RenderElementProps) => {
  switch ((element as SlateElement).type) {
    case 'mention':
      return (
        <Mention
          attributes={attributes}
          element={element}
          children={children}
        />
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};
