import { SearchCode } from 'lucide-react';

import { IconWithBackground } from '@spektr/client/components';
import { cn } from '@spektr/client/utils';

export type MonitoringDatasetNodeProps = {
  title: string;
};

export const MonitoringDatasetNode = ({
  title,
}: MonitoringDatasetNodeProps) => {
  return (
    <>
      <div>
        <IconWithBackground
          className={cn('ml-5 mr-4 h-10 w-10', 'bg-color-cyan')}
        >
          <SearchCode className="stroke-color-stroke-white h-4 w-4" />
        </IconWithBackground>
      </div>
      <div className="flex flex-col gap-0.5">
        <h3 className="text-color-text-node text-sm font-medium">{title}</h3>
        <span className="text-color-text-node-subtext text-xs">
          Field watch step
        </span>
      </div>
    </>
  );
};
