import { Trash } from 'lucide-react';

import { cn } from '@spektr/client/utils';

export type ConfigHeaderProps = {
  title: string;
  onDelete: () => void;
};

export const ConfigHeader = ({ title, onDelete }: ConfigHeaderProps) => {
  return (
    <div className={cn('flex flex-row justify-center', 'p-3')}>
      <label className="text-color-text-secondary text-sm font-semibold">
        {title}
      </label>
      <button className="ml-auto" onClick={onDelete}>
        <Trash className="stroke-color-stroke-secondary h-3.5 w-3.5" />
      </button>
    </div>
  );
};
