import { cn } from '@spektr/client/utils';

import { useViewpoints } from '../../hooks/useViewpoints';

import { ViewpointItem } from '../../components/ViewpointItem';

export const Viewpoints = () => {
  const { isViewpointActive, setViewpoint } = useViewpoints();

  return (
    <div className={cn('flex flex-row gap-1')}>
      <ViewpointItem
        name="mobile"
        icon="Smartphone"
        isActive={isViewpointActive('mobile')}
        onClick={setViewpoint}
      />
      <ViewpointItem
        name="desktop"
        icon="Monitor"
        isActive={isViewpointActive('desktop')}
        onClick={setViewpoint}
      />
    </div>
  );
};
