import { ReactNode } from 'react';

import { cn } from '@spektr/client/utils';

export type FormWidgetSidebarProps = {
  children: ReactNode | ReactNode[];
  className?: string;
  title: string;
};

export const FormWidgetSidebar = ({
  children,
  className,
  title,
}: FormWidgetSidebarProps) => {
  return (
    <div
      className={cn(
        'flex shrink-0 flex-col',
        'h-full w-full',
        'bg-color-bg-secondary',
        'dark:border-color-border-secondary border-color-border-tertiary border-r py-3',
        className
      )}
    >
      <p className="text-color-text-secondary px-3 text-base font-medium">
        {title}
      </p>
      {children}
    </div>
  );
};
