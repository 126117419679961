import { cn } from '@spektr/client/utils';

import { FormatButton } from './FormatButton';

type ToolbarOptionsProps = {
  className?: string;
  options: string[];
};

export const ToolbarOptions = ({ className, options }: ToolbarOptionsProps) => {
  return (
    <div className={cn('flex items-center gap-1', className)}>
      {options.includes('bold') && (
        <FormatButton format="bold" iconName="Bold" />
      )}
      {options.includes('italic') && (
        <FormatButton format="italic" iconName="Italic" />
      )}
      {options.includes('underline') && (
        <FormatButton format="underline" iconName="Underline" />
      )}
    </div>
  );
};
