import { z } from 'zod';

import { objectIdSchema, responseBaseSchema } from '../common';
import { recordToMap } from '../utils';
import { loopSourceSchema, processNodeSchema } from '../node';

export const processTypes = ['risk', 'score', 'monitoring', 'loop'] as const;
export const ProcessType = z.enum(processTypes);
export type ProcessType = z.infer<typeof ProcessType>;

export const processTypesSchema = z.enum(processTypes);

export const createProcessSchema = z.object({
  name: z.string().min(1).max(128),
  type: processTypesSchema,
});

export const updateProcessSchema = createProcessSchema.partial();

export const processSchema = createProcessSchema
  .merge(responseBaseSchema)
  .merge(
    z.object({
      rootId: objectIdSchema.optional(),
      mappings: z.record(z.string()),
      nodes: z.record(objectIdSchema, processNodeSchema).transform(recordToMap),
    })
  );

export const deprecatedCreateLoopProcessSchema = createProcessSchema.merge(
  z.strictObject({
    sources: z.array(loopSourceSchema).default([]),
  })
);

export const FindAllProcessesTypesQuery = z
  .array(processTypesSchema)
  .or(processTypesSchema)
  .optional()
  .transform((v) => (Array.isArray(v) ? v : v ? [v] : undefined));

export type FindAllProcessesTypesQuery = z.infer<
  typeof FindAllProcessesTypesQuery
>;

/**
 * Topology Schema
 */
export const topologyProcessLinkSchema = z.object({
  parentProcessId: objectIdSchema,
  outcomeId: objectIdSchema,
  childProcessId: objectIdSchema,
});

export const topologyProcessOutcomeSchema = z.object({
  id: objectIdSchema,
  title: z.string(),
  isFinal: z.boolean(),
});

export const topologyProcessSchema = z.object({
  id: objectIdSchema,
  name: z.string(),
  type: processTypesSchema,
  links: z.array(topologyProcessLinkSchema),
  outcomes: z.array(topologyProcessOutcomeSchema),
});

/**
 * Copy Workspace Process Schema
 */
export const copyWorkspaceProcess = z.object({
  sourceWorkspaceId: z.string().min(1),
  destinationWorkspaceId: z.string().min(1),
  processId: objectIdSchema,
});

export const UpdateDependencyMappings = z.object({
  dependency: z.string(), // the downstream custom field
  supplier: z.string(), // the upstream supplied field
  source: z.enum(['process', 'dataset']), // 'dataset' if 'supplier' comes from a dataset, 'process' if it comes from a process.
  sourceId: objectIdSchema, // the id of the process or dataset that supplies the field 'supplier'
});
export type UpdateDependencyMappings = z.infer<typeof UpdateDependencyMappings>;
export const BatchUpdateDependencyMappings = z.array(UpdateDependencyMappings);
export type BatchUpdateDependencyMappings = z.infer<
  typeof BatchUpdateDependencyMappings
>;

export type CopyWorkspaceProcess = z.infer<typeof copyWorkspaceProcess>;
