import { z } from 'zod';

import { objectIdSchema } from '../common';
import { PaginationFields } from '../pagination';

import {
  decisionSchema,
  hitDataSchema,
  hitListSchema,
  hitListSchemaResponse,
  vendorHitSchemaBaseWithId,
} from './hits';

export const actionSchemaBase = z.object({
  processId: objectIdSchema,
  contextId: objectIdSchema,
  workspaceId: z.string().min(1),
  nodeId: objectIdSchema,
  spektrId: objectIdSchema,
  datasetId: objectIdSchema,
  status: z.enum(['resolved', 'pending']),
  resolvedAt: z.number().optional(),
  resolvedBy: z.string().optional(),
});

export type ActionBase = z.infer<typeof actionSchemaBase>;
export const actionSchemaWithBaseHits = actionSchemaBase.merge(
  z.object({
    id: objectIdSchema,
    hits: z.array(vendorHitSchemaBaseWithId),
    createdAt: z.number(),
    updatedAt: z.number(),
  })
);
export type Action = z.infer<typeof actionSchemaWithBaseHits>;

export const actionSchemaResponse = z.object({
  processId: objectIdSchema,
  contextId: objectIdSchema,
  workspaceId: z.string().min(1),
  nodeId: objectIdSchema,
  spektrId: objectIdSchema,
  datasetId: objectIdSchema,
  status: z.enum(['resolved', 'pending']),
  resolvedAt: z.number().optional(),
  resolvedBy: z.string().optional(),
  hits: hitListSchemaResponse,
  createdAt: z.number(),
  updatedAt: z.number(),
  id: objectIdSchema,
});
export type ActionResponse = z.infer<typeof actionSchemaResponse>;
export const actionListResponseSchema = z.array(actionSchemaResponse);
export const actionListResponsePaginatedSchema = PaginationFields.merge(
  z.strictObject({
    docs: actionListResponseSchema,
  })
);
export type ActionListResponsePaginated = z.infer<
  typeof actionListResponsePaginatedSchema
>;

export const actionListSchema = z.array(actionSchemaWithBaseHits);
export type ActionList = z.infer<typeof actionListSchema>;

export const actionUpdateSchema = z.strictObject({
  status: z.enum(['resolved', 'pending']),
  hits: hitListSchema.optional(),
  reason: z.string().optional(),
});
export type ActionUpdate = z.infer<typeof actionUpdateSchema>;

const HitModelCreateSchema = z.object({
  id: z.string(),
  nodeType: z.string(),
  nodeId: z.string(),
  processId: z.string(),
  spektrId: z.string(),
  datasetId: z.string(),
  data: hitDataSchema,
  decision: decisionSchema.default({ status: 'pending' }),
});

export const ActionModelCreateSchema = z.object({
  processId: z.string(),
  contextId: z.string(),
  workspaceId: z.string().min(1),
  nodeId: z.string(),
  spektrId: z.string(),
  datasetId: z.string(),
  status: z.enum(['resolved', 'pending']),
  hits: z.array(HitModelCreateSchema),
});
export type ActionModelCreate = z.infer<typeof ActionModelCreateSchema>;

/**
 * Response schemas
 */
export const getActionsResponseSchema = PaginationFields.merge(
  z.strictObject({
    docs: actionListSchema,
  })
);
export type GetActionsResponse = z.infer<typeof getActionsResponseSchema>;

export const getActionByIdResponseSchema = actionSchemaWithBaseHits;
export type GetActionByIdResponse = z.infer<typeof getActionByIdResponseSchema>;
