import { FindAllProcessesTypesQuery } from '@spektr/shared/validators';

import { NodesApiClient, ProcessApiClient } from './client';
import {
  getAllowedSpektrFieldsQueryKey,
  getDependenciesQueryKey,
  getLiveProcessVersionsQueryKey,
  getLiveVersionByProcessIdQueryKey,
  getNodeQueryKey,
  getProcessLinksByIdQueryKey,
  getProcessQueryKey,
  getProcessesQueryKey,
  getTopologyQueryKey,
  getVersionOverviewByProcessIdQueryKey,
} from './query.keys';

export function getNodeByIdQuery(processId: string, nodeId: string) {
  return {
    queryKey: getNodeQueryKey(processId, nodeId),
    queryFn: () =>
      NodesApiClient.getClient().getNodeById({
        params: { processId, nodeId },
      }),
  };
}

export function getProcessByIdQuery(id: string) {
  return {
    queryKey: getProcessQueryKey(id),
    queryFn: () =>
      ProcessApiClient.getClient().getProcessById({
        params: { id },
      }),
  };
}

export type GetProcessesQueryParams = {
  types?: FindAllProcessesTypesQuery;
};
export function getProcessesQuery(
  { types }: GetProcessesQueryParams = {
    types: ['loop', 'score', 'risk', 'monitoring'],
  }
) {
  return {
    queryKey: getProcessesQueryKey(types),
    queryFn: () =>
      ProcessApiClient.getClient().getAllProcesses({
        queries: { types },
      }),
  };
}

export function getTopologyQuery() {
  return {
    queryKey: getTopologyQueryKey(),
    queryFn: () => ProcessApiClient.getClient().getAllTopology(),
  };
}

export function getAllowedSpektrFieldsQuery(processId: string, nodeId: string) {
  return {
    queryKey: getAllowedSpektrFieldsQueryKey(processId, nodeId),
    queryFn: () =>
      NodesApiClient.getClient().getAllowedSpektrfieldsForNode({
        params: { processId, nodeId },
      }),
  };
}

export function getProcessLinksByIdQuery(processId: string) {
  return {
    queryKey: getProcessLinksByIdQueryKey(processId),
    queryFn: () =>
      ProcessApiClient.getClient().getProcessLinks({
        params: { id: processId },
      }),
  };
}

export function getLiveProcessVersions() {
  return {
    queryKey: getLiveProcessVersionsQueryKey(),
    queryFn: () => ProcessApiClient.getClient().getAllLiveVersions(),
  };
}

export function getLiveProcessVersionByProcessId(processId: string) {
  return {
    queryKey: getLiveVersionByProcessIdQueryKey(processId),
    queryFn: async () => {
      try {
        return await ProcessApiClient.getClient().getLiveVersion({
          params: { processId },
        });
      } catch (error: any) {
        if (error.response?.status === 404) {
          const fallback = {
            id: '',
            processId,
            versionId: '',
            createdAt: 0,
            updatedAt: 0,
          };

          return fallback;
        }
        throw error;
      }
    },
  };
}

export function getVersionOverviewByProcessId(processId: string) {
  return {
    queryKey: getVersionOverviewByProcessIdQueryKey(processId),
    queryFn: () =>
      ProcessApiClient.getClient().getVersionOverviewByProcessId({
        params: {
          processId,
        },
      }),
  };
}

export function getProcessDependenciesQuery(processId: string) {
  return {
    queryKey: getDependenciesQueryKey(processId),
    queryFn: () =>
      ProcessApiClient.getClient().getProcessDependencies({
        params: { id: processId },
      }),
  };
}
