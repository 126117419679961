import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';

import { DashboardIcon } from '@radix-ui/react-icons';

import {
  loopDetailsUrl,
  processDetailsParamsSchema,
} from '@spektr/shared/utils';

import { useParsedParams } from '@spektr/shared/hooks';

import { BasicDialog } from '@spektr/client/components';

import { ProcessScheduleProvider } from './providers';

import { PublishVersion } from './containers/PublishVersion';

import { SettingItemSkeleton } from './components/SettingItemSkeleton';
import { VersionsSkeleton } from './components/VersionsSkeleton';
import { ProcessVersions } from './containers/ProcessVersions';

export const LoopScheduleDialog = () => {
  const navigate = useNavigate();
  const { processId } = useParsedParams(processDetailsParamsSchema);

  const handleClose = () => {
    navigate(loopDetailsUrl(processId));
  };

  return (
    <BasicDialog
      className="w-[620px] max-w-[620px]"
      defaultOpen
      title="Loop settings"
      icon={<DashboardIcon className="text-color-cyan mr-2 h-5 w-5" />}
      description="Create a version of your loop by publishing it. Version helps you save a state of your loop. Set your version to live to begin its execution."
      onClose={handleClose}
    >
      <ProcessScheduleProvider processId={processId}>
        <div className="divide-color-border-primary flex flex-col">
          <Suspense fallback={<SettingItemSkeleton />}>
            <PublishVersion
              processId={processId}
              title="Publish a version"
              description="Make a new live version of the current loop"
              isLoop={true}
            />
          </Suspense>
        </div>

        <Suspense fallback={<VersionsSkeleton />}>
          <ProcessVersions processId={processId} />
        </Suspense>
      </ProcessScheduleProvider>
    </BasicDialog>
  );
};
