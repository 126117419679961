import { Info } from 'lucide-react';

import {
  Select,
  SelectOption,
  SelectProps,
  Tooltip,
} from '@spektr/client/components';

export type SelectFieldProps = SelectProps & {
  options: SelectOption[];
  label: string;
  emptyLabel?: string;
  placeholder?: string;
  helperText?: string;
};

export const SelectField = ({
  label,
  helperText,
  name,
  ...props
}: SelectFieldProps) => {
  return (
    <div className="flex flex-col gap-2 px-3">
      <div className="flex flex-row gap-2">
        <label
          className="text-color-text-subtext text-xs font-medium"
          htmlFor={name}
        >
          {label}
        </label>
        {helperText && (
          <Tooltip
            content={helperText}
            side="right"
            align="end"
            contentClassName="bg-color-bg-tooltip mb-1"
          >
            <Info className="h-3 w-3" />
          </Tooltip>
        )}
      </div>
      <Select inverted {...props} />
    </div>
  );
};
