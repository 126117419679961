import { cva } from 'class-variance-authority';

import { cn } from '@spektr/client/utils';

export const dialogContentVariants = cva(
  cn(
    'grid p-6 md:w-full',
    'fixed left-1/2 top-1/2 z-50 translate-x-[-50%] translate-y-[-50%]',
    'border rounded-lg shadow-lg',
    'data-[state=open]:animate-in data-[state=closed]:animate-out',
    'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
    'data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95',
    'data-[state=closed]:slide-out-to-left-1/2',
    'data-[state=closed]:slide-out-to-top-[48%]',
    'data-[state=open]:slide-in-from-left-1/2',
    'data-[state=open]:slide-in-from-top-[48%]',
    '!duration-200'
  ),
  {
    variants: {
      size: {
        default: 'w-full max-w-lg',
        large: 'max-h-[90%] min-h-[500px] max-w-[800px]',
        full: [
          'w-full max-w-full',
          'h-full max-h-full',
          'rounded-none border-0',
          'focus:outline-none',
        ],
      },
      theme: {
        dark: [
          'border-color-border-primary',
          'bg-color-bg-dialog-default',
          'text-color-text-dialog-default',
        ],
        light: [
          'border-color-border-secondary',
          'bg-color-bg-dialog-inverted text-color-text-dialog-default',
        ],
      },
    },
    defaultVariants: {
      size: 'default',
      theme: 'dark',
    },
  }
);
