import { useLayoutEffect, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import ReactFlow, {
  Background,
  Node,
  Edge,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  FitViewOptions,
  Viewport,
} from 'reactflow';

import 'reactflow/dist/style.css';

import {
  BasicNode,
  EdgeToSegment,
  EdgeIntermediary,
  NewNodeLeaf,
  ProcessFilterNode,
  OutcomeNode,
  EdgeLeaf,
  LoopSourceNode,
  NODE,
} from '@spektr/shared/components';
import { generateGraph } from '@spektr/shared/utils';

import { Process, ProcessLink } from '@spektr/shared/types';

import { StickyVideoContainer } from '@spektr/client/components';
import { useDemo } from '@spektr/client/providers';

import { useLayout } from '../hooks';

const fitViewOptions: FitViewOptions = {
  padding: 0.95,
  // duration: 500,
};

const nodeTypes = {
  processFilter: ProcessFilterNode,
  loopSource: LoopSourceNode,
  addNew: NewNodeLeaf,
  basicNode: BasicNode,
  outcomeNode: OutcomeNode,
};

const edgeTypes = {
  default: EdgeLeaf,
  edgeIntermediary: EdgeIntermediary,
  edgeSegment: EdgeToSegment,
};

const options = { account: 'paid-pro', hideAttribution: true };

interface ProcessBuilderInnerProps {
  initialNodes: Node[];
  initialEdges: Edge[];
  defaultViewPort: Viewport | undefined;
  onMoveEnd: (viewport: Viewport) => void;
}

const ProcessBuilderInner = ({
  initialNodes,
  initialEdges,
  defaultViewPort,
  onMoveEnd,
}: ProcessBuilderInnerProps) => {
  const [nodes, _setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, _setEdges, onEdgesChange] = useEdgesState(initialEdges);

  useLayout();

  if (!defaultViewPort) return null; // dont render until we have a default viewport

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      proOptions={options}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      fitViewOptions={fitViewOptions}
      defaultViewport={defaultViewPort}
      minZoom={0.2}
      nodesDraggable={false}
      nodesConnectable={false}
      zoomOnDoubleClick={false}
      deleteKeyCode={null}
      onMoveEnd={(_event, viewPort) => onMoveEnd(viewPort)}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
    >
      <Background className="bg-color-bg-primary" />
    </ReactFlow>
  );
};

interface ProcessBuilderProps {
  process: Process;
  links?: ProcessLink[];
}

export const ProcessBuilder = ({
  process,
  links = [],
}: ProcessBuilderProps) => {
  const [canvasRef, { height, width }] = useMeasure<HTMLDivElement>();
  const [viewPort, setViewPort] = useState<Viewport | undefined>(undefined); // we cannot use `height` and `width` here on first render bc they are both 0 on first render
  const { isDemo } = useDemo();

  useLayoutEffect(() => {
    if (!height || !width) return;

    // set initial viewport position to be center of canvas
    setViewPort((prev) => ({
      zoom: prev?.zoom ?? 1,
      x: width * 0.5 - NODE.WIDTH * 0.5,
      y: height * 0.5 - NODE.HEIGHT * 0.5,
    }));
  }, [height, width]);

  const { nodes, edges } = useMemo(() => {
    return generateGraph(process, links);
  }, [process, links]);

  const { source, avatar } = useMemo((): {
    source: string;
    avatar: string;
  } => {
    if (!isDemo) {
      return {
        source: '',
        avatar: '',
      };
    }

    const baseVideoPath = 'https://platform.demo.spektr.com/videos/';

    switch (process.name) {
      case 'Risk Assessment - individuals':
        return {
          source: `${baseVideoPath}ra_individual.mp4`,
          avatar: 'bg-avatar-mikkel',
        };
      case 'Risk Assessment - companies':
        return {
          source: `${baseVideoPath}ra_corporate.mp4`,
          avatar: 'bg-avatar-jeremy',
        };
      case 'AML monitoring':
        return {
          source: `${baseVideoPath}monitor_aml.mp4`,
          avatar: 'bg-avatar-jeremy',
        };
      case 'Data enrichment monitoring':
        return {
          source: `${baseVideoPath}monitor_enrich.mp4`,
          avatar: 'bg-avatar-jeremy',
        };
      case 'Your first loop - Collecting Source of Funds proof':
        return {
          source: `${baseVideoPath}loop_sof.mp4`,
          avatar: 'bg-avatar-jeremy',
        };
      case 'Solution to AML hits':
        return {
          source: `${baseVideoPath}loop_aml.mp4`,
          avatar: 'bg-avatar-jeremy',
        };
      case 'Solution to new company owners detected':
        return {
          source: `${baseVideoPath}loop_owners.mp4`,
          avatar: 'bg-avatar-jeremy',
        };
      case 'Solution to new company name detected':
        return {
          source: `${baseVideoPath}loop_new_company_name.mp4`,
          avatar: 'bg-avatar-jeremy',
        };
      case 'Solution to high risk customer detected':
        return {
          source: `${baseVideoPath}loop_high_risk.mp4`,
          avatar: 'bg-avatar-jeremy',
        };
      default:
        return {
          source: '',
          avatar: '',
        };
    }
  }, [isDemo, process?.name]);

  return (
    <div ref={canvasRef} className="h-full w-full">
      <ReactFlowProvider>
        <ProcessBuilderInner
          key={process.updatedAt}
          initialEdges={edges}
          initialNodes={nodes}
          defaultViewPort={viewPort}
          onMoveEnd={setViewPort}
        />
      </ReactFlowProvider>
      {source && avatar && (
        <StickyVideoContainer
          title="Check out our video guide"
          source={source}
          avatar={avatar}
        />
      )}
    </div>
  );
};
