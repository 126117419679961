import { useQuery } from '@tanstack/react-query';
import _debounce from 'lodash/debounce';
import { Clipboard, Trash2 } from 'lucide-react';
import { useState } from 'react';

import { processNodeParamsSchema } from '@spektr/shared/utils';

import { getAllowedSpektrFieldsQuery } from '@spektr/client/services';

import { BasicDialog, Button } from '@spektr/client/components';
import { LoopFormBuilder } from '@spektr/client/loop-form-builder';
import { useParsedParams, useUpdateLoopNode } from '@spektr/shared/hooks';

import {
  RuleGroup,
  updateActionFormNodeSchema,
  type WidgetInstance,
} from '@spektr/shared/validators';

import { useFeatureFlags } from '@spektr/platform-hooks';
import {
  IncompleteRuleGroup,
  RuleGroupBody,
  RuleGroupCard,
  RuleGroupHeader,
  RuleGroupProvider,
  TitleInput,
  parseRuleGroup,
} from '@spektr/shared/components';
import { ActionFormNode, NodeUpdateInput } from '@spektr/shared/types';

export type FormBuilderProps = {
  node: ActionFormNode;
  onClose: () => void;
};

const DEFAULT_RULE_GROUP: IncompleteRuleGroup = {
  clientSideOnlyId: 'default-rule-group-form-node',
  id: 'default-rule-group-form-node',
  title: 'Default Rule Group',
  score: undefined,
  rule: {
    isIntermediate: false,
    id: 'default-rule-group-form-node-rule',
    groupRoot: true,
  },
};

export const FormBuilder = ({ node, onClose }: FormBuilderProps) => {
  const { processId } = useParsedParams(processNodeParamsSchema);
  const { moonraker } = useFeatureFlags();
  const { data: spektrFields } = useQuery({
    ...getAllowedSpektrFieldsQuery(processId, node.id),
    retry: false,
  });

  const [group, setGroup] = useState<IncompleteRuleGroup>(
    node.ruleGroup ? parseRuleGroup(node.ruleGroup) : DEFAULT_RULE_GROUP
  );
  const parsedGroup = RuleGroup.safeParse(group);

  const updateMutation = useUpdateLoopNode(processId);

  const handleUpdateNode = (data: NodeUpdateInput) => {
    updateMutation.mutateAsync({
      nodeId: node.id,
      node: data,
    });
  };

  const debouncedHandleUpdateNode = _debounce(handleUpdateNode, 500);

  const handleSaveWidgets = (widgets: WidgetInstance[]) => {
    const evaluated = updateActionFormNodeSchema.safeParse({
      nodeType: node.nodeType,
      form: widgets,
    });

    if (!evaluated.success) {
      return;
    }

    debouncedHandleUpdateNode(evaluated.data);
  };

  return (
    <BasicDialog
      className="w-[1014px] max-w-[1014px] gap-3"
      defaultOpen
      title="Build a form"
      icon={<Clipboard className="text-color-text-icon-primary mr-3 h-5 w-5" />}
      size="large"
      onClose={onClose}
    >
      {moonraker && (
        <RuleGroupProvider
          group={group}
          spektrFields={spektrFields ?? []}
          onChange={(updates) => {
            if (updates) setGroup({ ...group, ...updates });
          }}
        >
          <RuleGroupCard>
            <RuleGroupHeader className="flex justify-between">
              <TitleInput
                className="text-color-text-primary hover:ring-spektr-zinc-600 focus:ring-spektr-zinc-600 pl-1 text-sm"
                title={group.title ?? ''}
                onChange={(title) => setGroup({ ...group, title })}
              />
              <div className="flex">
                <Button
                  variant="text"
                  className="group"
                  onClick={() => {
                    updateMutation
                      .mutateAsync({
                        nodeId: node.id,
                        node: {
                          nodeType: 'actionForm',
                          form: node.form,
                          title: node.title,
                          ruleGroup: null,
                        },
                      })
                      .then(() => setGroup(DEFAULT_RULE_GROUP));
                  }}
                >
                  <Trash2 className="text-color-text-icon-tertiary group-hover:text-color-red h-4 w-4" />
                </Button>
                <Button
                  className="my-auto"
                  color="cyan"
                  type="button"
                  size="sm"
                  disabled={updateMutation.isPending || !parsedGroup.success}
                  onClick={() => {
                    if (!parsedGroup.success) return;

                    handleUpdateNode({
                      nodeType: 'actionForm',
                      form: node.form,
                      title: node.title,
                      ruleGroup: parsedGroup.data,
                    });
                  }}
                >
                  Save
                </Button>
              </div>
            </RuleGroupHeader>
            <RuleGroupBody />
          </RuleGroupCard>
        </RuleGroupProvider>
      )}
      <LoopFormBuilder
        widgets={node.form ?? []}
        spektrFields={spektrFields ?? []}
        onUpdate={handleSaveWidgets}
      />
    </BasicDialog>
  );
};
