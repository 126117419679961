import { ReactEditor } from 'slate-react';

import { SlateElement } from '../types';

export const withMentions = (editor: ReactEditor) => {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = (element) => {
    return (element as SlateElement).type === 'mention'
      ? true
      : isInline(element);
  };

  editor.isVoid = (element) => {
    return (element as SlateElement).type === 'mention'
      ? true
      : isVoid(element);
  };

  editor.markableVoid = (element) => {
    return (
      (element as SlateElement).type === 'mention' || markableVoid(element)
    );
  };

  return editor;
};
