import { z } from 'zod';

export const validationTypes = ['required'] as const;

export const validationTypesEnum = z.enum(validationTypes);

export const FieldValidation = z.object({
  type: validationTypesEnum,
  message: z.string(),
});
