import { useCallback } from 'react';
import { useMeasure, useWindowSize } from 'react-use';
import { useSuspenseQuery } from '@tanstack/react-query';

import { getLoopSourcesFromRootNode } from '@spektr/shared/utils';

import { getActionFieldsQuery } from '@spektr/client/services';
import { ActionNodeFields } from '@spektr/shared/validators';

import { ScrollArea } from '@spektr/client/components';

import { Process, ProcessNode } from '@spektr/shared/types';

import { ProcessFieldsDetails } from '../ProcessFieldsDetails';

export type ActionFieldsListProps = {
  process: Process;
  node: ProcessNode;
};

export const ActionFieldsList = ({ process, node }: ActionFieldsListProps) => {
  const { data: actionFields } = useSuspenseQuery(
    getActionFieldsQuery(process.id, node.id)
  );
  const [ref, { height: contentHeight }] = useMeasure<HTMLDivElement>();
  const { height: windowHeight } = useWindowSize();

  const sources = getLoopSourcesFromRootNode(process);

  const renderProcessCard = useCallback(
    (action: ActionNodeFields, index: number) => {
      return (
        <div key={`${action.title}-${index}`}>
          {action.actions.map((action) => (
            <ProcessFieldsDetails
              key={`${action.processId}-${action.nodeId}`}
              action={action}
              sources={sources.filter(
                (source) => source.processId === action.processId
              )}
            />
          ))}
        </div>
      );
    },
    [sources]
  );

  const percentageHeight = windowHeight * 0.7;

  return (
    <ScrollArea
      style={{
        height:
          contentHeight > percentageHeight ? percentageHeight : contentHeight,
      }}
    >
      <div ref={ref} className="flex flex-col gap-4">
        {actionFields.map(renderProcessCard)}
      </div>
    </ScrollArea>
  );
};
