import { CopyPlus, Trash } from 'lucide-react';

import { cn } from '@spektr/client/utils';

export type ToolbarProps = {
  onClone: () => void;
  onDelete: () => void;
};

const baseClassName = cn(
  'flex items-center justify-center',
  'h-8 w-8',
  'bg-color-bg-secondary',
  'transition-colors'
);

export const Toolbar = ({ onClone, onDelete }: ToolbarProps) => {
  return (
    <div className="flex items-center">
      <div
        className={cn(
          'flex flex-col',
          'rounded-lg',
          'divide-color-border-secondary divide-y',
          'shadow-2xl'
        )}
      >
        <button
          className={cn(
            baseClassName,
            'rounded-t-lg',
            'text-color-text-button-secondary/60',
            'hover:bg-color-bg-button-primary'
          )}
          onClick={onClone}
        >
          <CopyPlus className="h-3 w-3" />
        </button>
        <button
          className={cn(
            baseClassName,
            'rounded-b-lg',
            'text-color-red',
            'hover:bg-color-red/5'
          )}
          onClick={onDelete}
        >
          <Trash className="h-3 w-3" />
        </button>
      </div>
    </div>
  );
};
