import { ReactNode } from 'react';

import { cn } from '@spektr/client/utils';

import { useFormRouter } from '../../hooks/useFormRouter';

export type FormDialogContentProps = {
  children: ReactNode | ReactNode[];
};

export const FormDialogContent = ({ children }: FormDialogContentProps) => {
  const { isActiveRoute } = useFormRouter();

  return (
    <div
      className={cn(
        'grid',
        'h-full',
        'bg-color-bg-foreground',
        isActiveRoute('design')
          ? 'grid-cols-[64px_420px_auto]'
          : 'grid-cols-[64px_256px_auto]'
      )}
    >
      {children}
    </div>
  );
};
