import { cn } from '@spektr/client/utils';

import { ChevronDown } from 'lucide-react';

import type { InputField } from '@spektr/moonraker-types';

export type PhoneInputContentProps = {
  field: InputField;
};

export const PhoneInputContent = ({ field }: PhoneInputContentProps) => {
  return (
    <div className="flex w-full flex-col gap-2">
      <div className="flex items-center gap-4">
        <div
          className={cn(
            'flex shrink-0 items-center',
            'h-9 w-32 px-3 py-2',
            'border-color-border-input-inverted rounded-md border'
          )}
        >
          <span
            className={cn(
              'text-color-text-subtext',
              'line-clamp-1 text-xs font-medium'
            )}
          >
            Country code
          </span>
          <ChevronDown className="text-color-text-icon-secondary ml-auto h-4 w-4 shrink-0" />
        </div>
        <div
          className={cn(
            'flex items-center',
            'h-9 w-full px-3',
            'border-color-border-input-inverted rounded-md border'
          )}
        >
          {field.attributes?.placeholder && (
            <span
              className={cn('text-color-text-subtext', 'text-xs font-medium')}
            >
              {field.attributes.placeholder}
            </span>
          )}
        </div>
      </div>
      <span
        className={cn(
          'h-0',
          'text-color-text-subtext',
          'text-xs font-medium',
          'transition-all',
          { 'h-4': !!field.attributes?.helperText }
        )}
      >
        {field.attributes?.helperText}
      </span>
    </div>
  );
};
