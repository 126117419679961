import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  LOOPS_KEYS,
  LoopApiClient,
  NodesApiClient,
  ProcessApiClient,
  getProcessesQueryKey,
} from '@spektr/client/services';

import { loopDetailsUrl, processBuilderUrl } from '@spektr/shared/utils';

export const useCreateProcessWithSource = (
  sourceProcessId: string,
  sourceOutcomeId: string
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const name = 'Untitled';
  const source = {
    outcomeId: sourceOutcomeId,
    processId: sourceProcessId,
  };

  const createFilterNodeMutation = useMutation({
    mutationFn: async (id: string) => {
      await NodesApiClient.getClient().createNode(
        {
          nodeType: 'filter',
          source: {
            ...source,
            type: 'process',
          },
        },
        {
          params: { processId: id },
        }
      );

      await queryClient.invalidateQueries({
        queryKey: getProcessesQueryKey(),
      });

      navigate(processBuilderUrl(id));
    },
  });

  const createProcessMutation = useMutation({
    mutationFn: async (type: 'risk' | 'monitoring' | 'loop') => {
      const process = await ProcessApiClient.getClient().createProcess({
        name,
        type,
      });

      await createFilterNodeMutation.mutateAsync(process?.id);
    },
  });

  const createLoopMutation = useMutation({
    mutationFn: () =>
      LoopApiClient.getClient().createLoop({
        type: 'loop',
        name,
        sources: [source],
      }),
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: LOOPS_KEYS.ALL() });
      await queryClient.resetQueries({ queryKey: getProcessesQueryKey() });
      navigate(loopDetailsUrl(data.id));
    },
  });

  const createProcess = async (type: 'risk' | 'monitoring' | 'loop') => {
    if (type === 'loop') {
      await createLoopMutation.mutateAsync();
    } else {
      await createProcessMutation.mutateAsync(type);
    }
  };

  return createProcess;
};
