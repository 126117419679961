import { Element, Descendant, BaseText } from 'slate';

export type InlineFormats = 'bold' | 'italic' | 'underline';

export type SlateText = BaseText & {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
};

export type SlateTextElement = Element & {
  type: 'paragraph';
  children: SlateText[];
};

export type SlateMentionElement = Element & {
  type: 'mention';
  entity: string;
  children: SlateText[];
};

export type SlateElement = SlateTextElement | SlateMentionElement;

export type SlateDescendant = SlateElement | Descendant;

export const isSlateElement = (node: SlateDescendant): node is SlateElement => {
  return Element.isElementType(node, 'paragraph');
};
