import { useCallback } from 'react';
import { format } from 'date-fns';

import { FULL_DATE_TIME_FORMAT } from '@spektr/client/constants';
import {
  cn,
  getStatusColor,
  getStatusText,
  isExecutionFinished,
  isExecutionSuccessful,
} from '@spektr/client/utils';

import {
  Avatar,
  Status,
  ProcessIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@spektr/client/components';

import { ExecutionContextWithDatasetSchema } from '@spektr/shared/validators';
import { Process } from '@spektr/shared/types';

const baseThClasses = 'text-xs text-color-text-table-header p-3';
const baseRowCellClasses = 'px-4 border-b';

export type ExecutionsTableProps = {
  executions: ExecutionContextWithDatasetSchema[];
  processes: Process[];
};

export const ExecutionsTable = ({
  executions = [],
  processes,
}: ExecutionsTableProps) => {
  // TODO: (@Robert) - Add navigation to the details page once we are ready for it
  // const navigate = useNavigate();

  // const handleClickRow = useCallback(
  //   (id: string) => {
  //     navigate(insightsRunUrl(id));
  //   },
  //   [navigate]
  // );

  const renderRow = useCallback(
    (row: ExecutionContextWithDatasetSchema, index: number) => {
      const isFirstRow = index === 0;
      const isLastRow = index === executions.length - 1;

      const currentProcess = processes.find(
        (process) => process.id === row.processId
      );

      const name = row.dataset.fieldId
        ? row.data[row.dataset.fieldId]?.toString() ?? ''
        : 'Unknown';

      return (
        <TableRow
          key={row.id}
          className="hover:bg-color-bg-table-row--hover border-b"
          // onClick={() => handleClickRow(row.spektrId)}
        >
          <TableCell
            className={cn(baseRowCellClasses, 'border-x', {
              'rounded-bl-md': isLastRow,
              'w-72': isFirstRow,
            })}
          >
            <div className="flex items-center gap-3">
              <Avatar firstName={name} lastName="" />
              <div>
                <p className="text-color-text-primary text-sm">{name}</p>
                <p className="text-color-text-subtext text-xs">
                  Part of{' '}
                  <span className="text-color-text-primary">
                    {row.dataset.name}
                  </span>
                </p>
              </div>
            </div>
          </TableCell>
          <TableCell className={baseRowCellClasses}>
            <div className="flex items-center gap-3">
              <ProcessIcon processType={currentProcess?.type} />
              <p className="text-color-text-primary text-sm font-medium">
                {row.process.name}
              </p>
            </div>
          </TableCell>
          <TableCell className={baseRowCellClasses}>
            <p className="text-color-text-subtext text-xs font-medium">
              {format(row.createdAt, FULL_DATE_TIME_FORMAT)}
            </p>
          </TableCell>
          <TableCell className={baseRowCellClasses}>
            {isExecutionFinished(row) ? (
              <p className="text-color-text-subtext text-xs font-medium">
                {format(row.updatedAt, FULL_DATE_TIME_FORMAT)}
              </p>
            ) : undefined}
          </TableCell>
          <TableCell className={baseRowCellClasses}>
            <p className="text-whitcolor-text-primarye text-xs font-medium">
              {row.data.score}
            </p>
          </TableCell>
          <TableCell className={baseRowCellClasses}>
            {isExecutionSuccessful(row) ? (
              <p className="text-color-text-primary text-xs font-medium">
                {row.process.output === 'unknown'
                  ? 'Finished'
                  : row.process.output}
              </p>
            ) : undefined}
          </TableCell>
          <TableCell
            className={cn(baseRowCellClasses, 'border-r', {
              'rounded-br-md': isLastRow,
            })}
          >
            <Status color={getStatusColor(row)} label={getStatusText(row)} />
          </TableCell>
        </TableRow>
      );
    },
    [executions.length, processes]
  );

  return (
    <Table className="border-separate">
      <TableHeader>
        <TableRow className="rounded-tl-md rounded-tr-md hover:bg-inherit">
          <TableHead className={cn('rounded-tl-md border', baseThClasses)}>
            Customer
          </TableHead>
          <TableHead className={cn(baseThClasses, 'border-b border-t')}>
            Latest run
          </TableHead>
          <TableHead className={cn(baseThClasses, 'border-b border-t')}>
            Run started at
          </TableHead>
          <TableHead className={cn(baseThClasses, 'border-b border-t')}>
            Run finished at
          </TableHead>
          <TableHead className={cn(baseThClasses, 'border-b border-t')}>
            Score
          </TableHead>
          <TableHead className={cn(baseThClasses, 'border-b border-t')}>
            Output
          </TableHead>
          <TableHead
            className={cn('rounded-tr-md border border-l-0', baseThClasses)}
          >
            Status
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {executions.length > 0 ? (
          executions.map(renderRow)
        ) : (
          <TableRow>
            <TableCell
              colSpan={7}
              className="rounded-bl-md rounded-br-md border-x border-b text-center"
            >
              No executions found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
