import { CopyPlus, Inbox, Pencil, Trash2 } from 'lucide-react';
import { MouseEvent, useMemo, useState } from 'react';

import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { Process } from '@spektr/shared/types';
import { processBuilderUrl } from '@spektr/shared/utils';

import { RenameProcessDialog } from '@spektr/model-builder/containers';

import {
  AlertDialog,
  DropdownOption,
  ProcessCard,
} from '@spektr/client/components';

import {
  ProcessApiClient,
  getLiveProcessVersions,
  getProcessesQuery,
  getProcessesQueryKey,
} from '@spektr/client/services';

type ProcessListProps = {
  status: string;
};

const ACTIONS: DropdownOption[] = [
  {
    type: 'item',
    label: 'Rename process',
    icon: <Pencil className="h-4 w-4" />,
    value: 'rename',
  },
  {
    type: 'separator',
    value: 'separator',
  },
  {
    type: 'item',
    label: 'Duplicate process',
    icon: <CopyPlus className="h-4 w-4" />,
    value: 'duplicate',
  },
  {
    type: 'separator',
    value: 'separator-2',
  },
  {
    type: 'item',
    label: 'Delete process',
    icon: <Trash2 className="h-4 w-4" />,
    value: 'delete',
    variant: 'danger',
  },
];

export const ProcessList = ({ status }: ProcessListProps) => {
  const queryClient = useQueryClient();
  const [confirmDeleteProcessId, setConfirmDeleteProcessId] = useState<
    string | undefined
  >(undefined);
  const [renameProcessId, setRenameProcessId] = useState<string | undefined>(
    undefined
  );
  const { data: allProcesses = [] } = useSuspenseQuery(
    getProcessesQuery({
      types: ['risk', 'score', 'monitoring'],
    })
  );
  const { data: liveProcessVersions } = useSuspenseQuery(
    getLiveProcessVersions()
  );
  const processes = useMemo(() => {
    return allProcesses?.filter((process) => {
      const isLive = liveProcessVersions?.some(
        (liveVersion) => liveVersion.processId === process.id
      );

      if (status === 'live') return isLive;

      if (status === 'not-live') return !isLive;

      return true; // default to 'all'
    });
  }, [allProcesses, liveProcessVersions, status]);

  const deleteProcess = useMutation({
    mutationFn: (processId: string) =>
      ProcessApiClient.getClient().deleteProcessById(undefined, {
        params: {
          id: processId,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getProcessesQueryKey() });
      setConfirmDeleteProcessId(undefined);
    },
  });

  const updateProcess = useMutation({
    mutationFn: ({ processId, name }: { processId: string; name: string }) =>
      ProcessApiClient.getClient().updateProcessById(
        {
          name,
        },
        {
          params: {
            id: processId,
          },
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getProcessesQueryKey() });
      setRenameProcessId(undefined);
    },
  });

  const duplicateProcess = useMutation({
    mutationFn: (loopId: string) =>
      ProcessApiClient.getClient().duplicateProcess(undefined, {
        params: {
          id: loopId,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getProcessesQueryKey() });
    },
  });

  const handleConfirmDelete = async () => {
    if (confirmDeleteProcessId) {
      await deleteProcess.mutateAsync(confirmDeleteProcessId);
    }
  };

  const handleActionClick =
    (processId: string) => (action: string, ev: MouseEvent) => {
      ev.stopPropagation();

      switch (action) {
        case 'rename':
          setRenameProcessId(processId);
          break;
        case 'delete':
          setConfirmDeleteProcessId(processId);
          break;
        case 'duplicate':
          duplicateProcess.mutateAsync(processId);
          break;
      }
    };

  const renderProcessCard = (process: Process) => {
    return (
      <ProcessCard
        actions={ACTIONS}
        key={process.id}
        route={processBuilderUrl(process.id)}
        routeState={{ processType: process.type }}
        name={process.name}
        type={process.type}
        isLive={liveProcessVersions?.some(
          (version) => version.processId === process.id
        )}
        updatedAt={process.updatedAt}
        onActionClick={handleActionClick(process.id)}
      />
    );
  };

  if (!processes || processes.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center gap-2 p-8">
        <Inbox className="stroke-color-cyan h-8 w-8" />
        <span className="text-color-text-error-boundry">
          No processes found. Create a new process to get started.
        </span>
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        {processes.map(renderProcessCard)}
      </div>
      <AlertDialog
        open={!!confirmDeleteProcessId}
        title="Are you sure?"
        paragraph="You will loose unsaved changes not published in a process version."
        onCancel={() => setConfirmDeleteProcessId(undefined)}
        cancel="Cancel"
        onConfirm={handleConfirmDelete}
        confirm="Yes, delete"
        confirmDataCy="Yes-delete"
      />
      <RenameProcessDialog
        process={processes?.find((process) => process.id === renameProcessId)}
        open={!!renameProcessId}
        onClose={() => {
          setRenameProcessId(undefined);
        }}
        onSave={(name: string) => {
          if (!renameProcessId) {
            return;
          }
          updateProcess.mutateAsync({ processId: renameProcessId, name });
        }}
      />
    </>
  );
};
