import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Label,
  RichTextEditor,
  Select,
  SelectOption,
} from '@spektr/client/components';

import { SpektrField } from '@spektr/shared/types';

const channelTypes = [
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'sms',
    label: 'SMS',
    disabled: true,
  },
  { value: 'api', label: 'API', disabled: true },
];

type LoopChannelSettingsProps = {
  spektrFields: SpektrField[];
};

export const LoopChannelSettings = ({
  spektrFields,
}: LoopChannelSettingsProps) => {
  const { watch, control } = useFormContext();

  const recipientFieldOptions = useMemo<SelectOption[]>(
    () =>
      spektrFields.map((field) => ({
        value: field.key,
        label: field.label,
      })),
    [spektrFields]
  );

  const channelType = watch('type');
  const isApi = channelType === 'api';

  return (
    <form className="flex flex-col gap-2">
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col gap-2">
          <Label>Type</Label>
          <Controller
            name="type"
            render={({ field }) => (
              <Select
                required
                {...field}
                defaultValue={'email'}
                placeholder="Select from dropdown..."
                options={channelTypes}
                onValueChange={field.onChange}
              />
            )}
            control={control}
          />
        </div>
        {!isApi && (
          <div className="flex flex-col gap-2">
            <Label>Recipient Field</Label>
            {/* TODO: @Alex - replace with SelectSpektrFieldCombobox after it's merged */}
            <Controller
              name="recipientField"
              render={({ field }) => (
                <Select
                  required
                  {...field}
                  placeholder="Select from dropdown..."
                  options={recipientFieldOptions}
                  onValueChange={field.onChange}
                />
              )}
              control={control}
            />
          </div>
        )}
      </div>
      {!isApi && (
        <div className="flex flex-col gap-2">
          <Label>Message Body</Label>
          <Controller
            name="emailBody"
            render={({ field }) => (
              <RichTextEditor
                floatingToolbar={false}
                className="h-40"
                placeholder="Please enter the content of your message"
                initialValue={field.value}
                mentionOptions={spektrFields.map((field) => field.key)}
                onChange={field.onChange}
              />
            )}
            control={control}
          />
        </div>
      )}
    </form>
  );
};
