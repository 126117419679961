import { ChevronRight } from 'lucide-react';

import { cn } from '@spektr/client/utils';

export type TitleProps = {
  processTitle: string;
  nodeTitle: string;
};

export const Title = ({ processTitle, nodeTitle }: TitleProps) => (
  <div className={cn('flex flex-row items-center gap-2')}>
    <p className={cn('text-sm font-medium', 'text-color-text-subtext')}>
      {processTitle}
    </p>
    <ChevronRight className="text-color-text-icon-secondary h-3 w-3" />
    <p className={cn('text-sm font-medium', 'text-color-text-secondary')}>
      {nodeTitle}
    </p>
  </div>
);
