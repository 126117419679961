import { PlusSquare } from 'lucide-react';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { RBAC } from '@spektr/shared/rbac';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogHeader,
  SpektrDataflowIcon,
} from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';

import { CreateProcessCardProcessCard } from './create-process-card-process-card';

export const CreateProcessDialog = () => {
  const { hasPermission } = usePermissionsContext();

  return (
    <Dialog>
      <DialogPrimitive.Trigger asChild>
        <Button
          className="whitespace-nowrap p-3.5 font-semibold"
          color="cyan"
          startIcon={<PlusSquare className="h-4 w-4 stroke-2" />}
          disabled={!hasPermission(RBAC.ACTIONS.PROCESS.CREATE)}
          data-cy="create-process-button"
        >
          Add new process
        </Button>
      </DialogPrimitive.Trigger>
      <DialogContent className="bg-color-bg-dialog-default max-w-[800px] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="mb-2 gap-3" data-cy="create-process-dialog">
            <SpektrDataflowIcon className="stroke-color-cyan h-5 w-5 stroke-2" />
            <h1 className="text-color-text-dialog-title">Create new process</h1>
          </DialogTitle>
          <DialogDescription className="bg-color-bg-dialog-description text-color-text-dialog-description rounded-md p-3">
            Processes will be your proudest creations. Take a risk approach to
            automatically calculate scores and assess your customers’ risk
            groups, or monitor a wide range of factors to stay informed about
            events requiring special attention.
          </DialogDescription>
        </DialogHeader>
        <div className="mt-4 flex flex-col gap-3">
          <CreateProcessCardProcessCard variant="risk" />
          <CreateProcessCardProcessCard variant="score" />
          <CreateProcessCardProcessCard variant="monitoring" />
        </div>
      </DialogContent>
    </Dialog>
  );
};
