import { loopSourceNodeSchema } from '@spektr/shared/validators';

import { LoopSource, Process } from '@spektr/shared/types';

import { getRootNode } from '../nodes';

/**
 * @description   Returns an array of loop sources from the given loop.
 * @param   loop  The loop to get the sources from.
 * @returns       An array of loop sources.
 */
export function getLoopSourcesFromRootNode(loop: Process) {
  const loopRootNode = getRootNode(loop);

  if (!loopRootNode) {
    return [];
  }

  const result = loopSourceNodeSchema.safeParse(loopRootNode);

  if (!result.success) {
    return [];
  }

  return result.data.sources;
}

/**
 * @description       Returns an array of unique process ids from the given loop sources.
 * @param   sources   The loop sources to get the process ids from.
 * @returns           An array of unique process ids.
 */
export function getProcessIdsFromSources(sources: LoopSource[]) {
  const loopsProcessIds = new Set();

  sources.forEach((source) => loopsProcessIds.add(source.processId));

  return Array.from(loopsProcessIds);
}

export function getLoopChannelSettingsFromRootNode(loop: Process) {
  const loopRootNode = getRootNode(loop);

  if (!loopRootNode) {
    return undefined;
  }

  const result = loopSourceNodeSchema.safeParse(loopRootNode);

  if (!result.success) {
    return undefined;
  }

  return result.data.channelSettings;
}
