import { useNavigate } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

import { IconButton } from '@spektr/client/components';
import { cn } from '@spektr/client/utils';
import { SETTINGS_WORKSPACE_FULL_URL } from '@spektr/shared/utils';

export const InviteMembersContainer = () => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(SETTINGS_WORKSPACE_FULL_URL)}
      className={cn(
        'flex h-[404px] w-full flex-col gap-6 p-4 xl:w-[38%]',
        'cursor-pointer rounded-md border',
        'bg-color-bg-card-default',
        'border-color-border-primary hover:bg-color-bg-card--hover'
      )}
    >
      <div className="bg-color-bg-card-inverted relative min-h-[260px] overflow-hidden rounded-md">
        <div
          className={cn(
            'h-full w-full',
            'absolute left-6 top-6',
            'rounded-md',
            'bg-invite-members bg-cover'
          )}
        ></div>
      </div>
      <div className="flex justify-between gap-6">
        <div className="flex flex-col gap-1">
          <h3 className="text-lg font-medium leading-tight">
            Get your colleagues on board
          </h3>
          <p className="text-color-text-subtext text-sm">
            Workspaces can contain as many accounts as you need to set up{' '}
          </p>
        </div>
        <IconButton
          className="h-9 w-9 shrink-0 self-end rounded-full p-0"
          color="white"
        >
          <ArrowRight className="h-5" />
        </IconButton>
      </div>
    </div>
  );
};
