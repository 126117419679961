import { BrandingFormAssistingContent } from '@spektr/branding-types';

export function createUpdateAssistingContentAction(
  payload: BrandingFormAssistingContent
) {
  return {
    type: 'update_assistingContent' as const,
    payload,
  };
}

export type UpdateAssistingContentAction = ReturnType<
  typeof createUpdateAssistingContentAction
>;
