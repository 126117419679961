import { RouteObject } from 'react-router-dom';

import {
  PROCESS_DETAILS_PARAM,
  SHEET_URL,
  PROCESS_FILTER_URL,
  PROCESS_NODE_PARAM,
  PROCESS_NODE_URL,
  PROCESS_SCHEDULE_URL,
  processBuilderUrl,
  STRATEGY_URL,
  FIELD_MAPPING_URL,
} from '@spektr/shared/utils';

import {
  ProcessActionsHeader,
  ProcessDetailsHeader,
} from '@spektr/model-builder/containers';

import { ProcessSchedulePage } from './Schedule';
import { ProcessBuilderSheet } from './sheet';
import { FilterNodeDialog } from './filter';
import { NodeDialog } from './NodeDialog';
import { ProcessBuilderDetailPage } from './ProcessBuilderDetailPage';
import { ProcessStrategyDialog } from './ProcessStrategyDialog';
import { ProcessFieldMappingDialog } from './ProcessFieldMappingDialog';

export const processBuilderIndexRoute: RouteObject = {
  path: PROCESS_DETAILS_PARAM,
  element: <ProcessBuilderDetailPage />,
  id: 'detail',
  handle: {
    backTo: {
      url: processBuilderUrl(),
      label: 'Processes',
    },
    renderLeft: () => <ProcessDetailsHeader />,
    renderRight: () => <ProcessActionsHeader />,
  },
  children: [
    {
      path: PROCESS_SCHEDULE_URL,
      element: <ProcessSchedulePage />,
    },
    {
      path: SHEET_URL,
      element: <ProcessBuilderSheet />,
    },
    {
      path: PROCESS_FILTER_URL,
      element: <FilterNodeDialog />,
    },
    {
      path: `${PROCESS_NODE_URL}/${PROCESS_NODE_PARAM}`,
      element: <NodeDialog />,
    },
    {
      path: STRATEGY_URL,
      element: <ProcessStrategyDialog />,
    },
    {
      path: FIELD_MAPPING_URL,
      element: <ProcessFieldMappingDialog />,
    },
  ],
};
