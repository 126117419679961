import { RouteObject } from 'react-router-dom';

import {
  SETTINGS_WORKSPACE_URL,
  SETTINGS_DEVELOPER_URL,
  SETTINGS_URL,
} from '@spektr/shared/utils';

import { SettingsIndexPage } from './IndexPage';
import { SettingsAccountPage } from './SettingsAccount';
import { SettingsWorkspacePage } from './SettingsWorkspace';
import { SettingsDeveloperPage } from './SettingsDeveloper';

export const settingsRoutes: RouteObject = {
  path: SETTINGS_URL,
  element: <SettingsIndexPage />,
  handle: {
    title: 'Settings',
  },
  children: [
    {
      index: true,
      element: <SettingsAccountPage />,
    },
    {
      element: <SettingsWorkspacePage />,
      path: SETTINGS_WORKSPACE_URL,
    },
    {
      element: <SettingsDeveloperPage />,
      path: SETTINGS_DEVELOPER_URL,
    },
  ],
};
