import { Suspense, lazy, useEffect, useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import {
  CHANGE_PASSWORD_URL,
  SET_UP_ACCOUNT_URL,
  CONFIRM_RESET_PASSWORD_URL,
  DASHBOARD_URL,
  LOGIN_URL,
  REGISTER_URL,
  RESET_PASSWORD_URL,
  CHOOSE_DEMO_OPTION_URL,
  STRATEGY_PAGE_URL,
} from '@spektr/shared/utils';
import { ErrorPage, GlobalErrorPage } from '@spektr/shared/components';
import { SpektrErrorBoundary, ToastProvider } from '@spektr/client/components';
import {
  DemoProvider,
  DEFAULT_THEME,
  ThemeProvider,
} from '@spektr/client/providers';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ReactQueryDevtoolsProduction } from './components/devtools';

import {
  RootLayout,
  RootPage,
  processBuilderRoute,
  loopsRoutes,
  insightsRoutes,
} from './routes';
import { AuthProvider } from './auth/auth-context';
import { AuthLayout, ProtectedRoute } from './components';

import changePasswordAction from './routes/ChangePassword.action';
import confirmResetPasswordAction from './routes/ConfirmResetPassword.action';
import resetPasswordAction from './routes/ResetPassword.action';

import rootLoader from './routes/Root.loader';
import { connectionHubRoutes } from './routes/connection-hub';

import { queryClient } from './queryClient';
import { settingsRoutes } from './routes/settings';
import { DemoLayout } from './routes/demo/components/DemoLayout';
import { IS_DEMO_ENV } from './config/network';
import LaunchDarklyConfig from './config/launchDarkly';
import { StrategyPage } from './routes/StrategyPage';

import './config/i18n';

const LoginPage = lazy(
  () => import(/* webpackChunkName: "LoginPage" */ './routes/Login')
);
const SignUpPage = lazy(
  () => import(/* webpackChunkName: "SignUpPage" */ './routes/SignUp')
);
const SetUpAccountPage = lazy(
  () =>
    import(/* webpackChunkName: "SetUpAccountPage" */ './routes/SetUpAccount')
);
const ChangePasswordPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ChangePasswordPage" */ './routes/ChangePassword'
    )
);
const ConfirmResetPasswordPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmResetPasswordPage" */ './routes/ConfirmResetPassword'
    )
);
const ResetPasswordPage = lazy(
  () =>
    import(/* webpackChunkName: "ResetPasswordPage" */ './routes/ResetPassword')
);
const ChoseDemoOptionPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ChooseDemoOptionPage" */ './routes/demo/ChooseDemoOption'
    )
);

const router = createBrowserRouter([
  {
    path: DASHBOARD_URL,
    element: <RootLayout />,
    loader: rootLoader,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {
            path: LOGIN_URL,
            element: <LoginPage />,
            handle: {
              title: 'Sign in',
            },
          },
          {
            path: REGISTER_URL,
            element: <SignUpPage />,
            handle: {
              title: 'Sign up',
            },
          },
          {
            path: SET_UP_ACCOUNT_URL,
            element: <SetUpAccountPage />,
            handle: {
              title: 'Set up account',
            },
          },
          {
            path: RESET_PASSWORD_URL,
            element: <ResetPasswordPage />,
            action: resetPasswordAction,
            handle: {
              title: 'Reset password',
            },
          },
          {
            path: CONFIRM_RESET_PASSWORD_URL,
            element: <ConfirmResetPasswordPage />,
            action: confirmResetPasswordAction,
            handle: {
              title: 'Reset password',
            },
          },
          {
            path: CHANGE_PASSWORD_URL,
            element: <ChangePasswordPage />,
            action: changePasswordAction,
            handle: {
              title: 'Reset password',
            },
          },
        ],
      },
      {
        element: <DemoLayout />,
        children: [
          {
            path: CHOOSE_DEMO_OPTION_URL,
            element: <ChoseDemoOptionPage />,
          },
        ],
      },
      {
        element: <ProtectedRoute />,
        errorElement: <GlobalErrorPage />,
        children: [
          {
            index: true,
            element: <RootPage />,
            loader: rootLoader,
          },
          processBuilderRoute,
          connectionHubRoutes,
          loopsRoutes,
          settingsRoutes,
          insightsRoutes,
          {
            path: STRATEGY_PAGE_URL,
            element: <StrategyPage />,
          },
        ],
      },
    ],
  },
]);

const App = () => {
  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    window.devtools = () => setShowDevtools((prev) => !prev);
  }, []);

  return (
    <SpektrErrorBoundary
      fallbackRender={(error) => {
        const errorMsg = axios.isAxiosError(error)
          ? error.response?.data?.message
          : 'Something went wrong when loading the loop details.';

        return <ErrorPage title={errorMsg} />;
      }}
    >
      <QueryClientProvider client={queryClient}>
        <DemoProvider isDemoEnv={IS_DEMO_ENV}>
          <AuthProvider>
            <ThemeProvider defaultTheme={DEFAULT_THEME}>
              <RouterProvider router={router} />
              <ToastProvider />
              <ReactQueryDevtools />
              {showDevtools && (
                <Suspense fallback={null}>
                  <ReactQueryDevtoolsProduction initialIsOpen={true} />
                </Suspense>
              )}
            </ThemeProvider>
          </AuthProvider>
        </DemoProvider>
      </QueryClientProvider>
    </SpektrErrorBoundary>
  );
};

export default withLDProvider(LaunchDarklyConfig)(App);
