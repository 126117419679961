import { useContext, useMemo } from 'react';

import { getExistingSource } from '@spektr/shared/utils';

import { ProcessScheduleContext } from './ProcessScheduleContext';

export const useProcessScheduleContext = () => {
  const { process } = useContext(ProcessScheduleContext);

  return useMemo(
    () => ({
      hasSource: process && !!getExistingSource(process),
      isRiskProcess: process?.type === 'risk' || process?.type === 'score',
      isLoopProcess: process?.type === 'loop',
    }),
    [process]
  );
};
