import { useMemo } from 'react';

import { useFeatureFlags } from '@spektr/platform-hooks';
import { ProcessType } from '@spektr/shared/validators';

import {
  BodaccIcon,
  ComplyAdvantageIcon,
  IconWithBackground,
  KyckrIcon,
  MitIdIcon,
  OpenCorporatesIcon,
  SlackIcon,
  VeriffIcon,
} from '@spektr/client/components';

import { LoopDrawerItem, ProcessDrawerItem } from '../types/DrawerItemType';

export const useProcessServices = (
  processType: ProcessType | undefined
): ProcessDrawerItem[] => {
  const { kycrAndCaoc } = useFeatureFlags();

  const risk = useMemo(() => {
    const services: ProcessDrawerItem[] = [
      {
        processType: 'process',
        type: 'complyAdvantageKyb',
        title: 'ComplyAdvantage',
        variant: 'complyAdvantage',
        label: 'Corporate',
        icon: <ComplyAdvantageIcon />,
        description:
          'Check for AML hits, sanctions and adverse media on your corporate customers.',
      },
      {
        processType: 'process',
        type: 'complyAdvantageKyc',
        title: 'ComplyAdvantage',
        variant: 'complyAdvantage',
        label: 'Individual',
        icon: <ComplyAdvantageIcon />,
        description:
          'Check for AML hits, sanctions, adverse media and PEP on your individual customers.',
      },
      {
        processType: 'process',
        type: 'openCorporatesRisk',
        title: 'OpenCorporates',
        icon: <OpenCorporatesIcon />,
        variant: 'openCorporates',
        label: undefined,
        description:
          'Verify a company, and get access numerous datapoints to build logic upon.',
      },
      {
        processType: 'process',
        type: 'bodacc',
        title: 'BODACC',
        icon: <BodaccIcon />,
        variant: 'bodacc',
        label: undefined,
        description:
          'Check for changes in the legal status of a company, such as bankruptcy or liquidation.',
      },
      {
        processType: 'process',
        type: 'kyckr',
        title: 'Kyckr',
        icon: <KyckrIcon />,
        variant: 'kyckr',
        label: undefined,
        description:
          'Discover Ultimate Beneficial Owners of your corporate customers.',
        hidden: !kycrAndCaoc,
      },
      {
        processType: 'process',
        type: 'slack',
        icon: (
          <IconWithBackground className="bg-color-bg-white">
            <SlackIcon className="h-6 w-6" />
          </IconWithBackground>
        ),
        title: 'Slack',
        variant: 'cyan' as const,
        description:
          'Create slack push node that will forward results of the process execution',
      },
    ];

    return services.filter((item) => !item.hidden);
  }, []);

  const monitoring = useMemo(() => {
    const services: ProcessDrawerItem[] = [
      {
        processType: 'process',
        type: 'complyAdvantageKyb',
        title: 'ComplyAdvantage',
        variant: 'complyAdvantage',
        label: 'Corporate',
        icon: <ComplyAdvantageIcon />,
        description:
          'Check for AML hits, sanctions and adverse media on your corporate customers.',
      },
      {
        processType: 'process',
        type: 'complyAdvantageKyc',
        title: 'ComplyAdvantage',
        variant: 'complyAdvantage',
        label: 'Individual',
        icon: <ComplyAdvantageIcon />,
        description:
          'Check for AML hits, sanctions, adverse media and PEP on your individual customers.',
      },
      {
        processType: 'process',
        type: 'openCorporatesMonitoring',
        title: 'OpenCorporates',
        icon: <OpenCorporatesIcon />,
        variant: 'openCorporates',
        label: undefined,
        description:
          'Verify a company, and get access numerous datapoints to build logic upon.',
      },
      {
        processType: 'process',
        type: 'bodacc',
        title: 'BODACC',
        icon: <BodaccIcon />,
        variant: 'bodacc',
        label: undefined,
        description:
          'Check for changes in the legal status of a company, such as bankruptcy or liquidation.',
      },
      {
        processType: 'process',
        type: 'kyckr',
        title: 'Kyckr',
        icon: <KyckrIcon />,
        variant: 'kyckr',
        description:
          'Discover Ultimate Beneficial Owners of your corporate customers.',
        hidden: !kycrAndCaoc,
      },
      {
        processType: 'process',
        type: 'slack',
        icon: (
          <IconWithBackground className="bg-color-bg-white">
            <SlackIcon className="h-6 w-6" />
          </IconWithBackground>
        ),
        title: 'Slack',
        variant: 'cyan' as const,
        description:
          'Create slack push node that will forward results of the process execution',
      },
    ];

    return services.filter((item) => !item.hidden);
  }, []);

  if (processType === 'risk' || processType === 'score') return risk;
  if (processType === 'monitoring') return monitoring;
  return [];
};

export const useLoopServices = (): LoopDrawerItem[] => {
  const { kycrAndCaoc, veriff, mitid } = useFeatureFlags();

  return useMemo(() => {
    const services: LoopDrawerItem[] = [
      {
        processType: 'loop',
        type: 'complyAdvantageKyb',
        title: 'ComplyAdvantage',
        variant: 'complyAdvantage',
        label: 'Corporate',
        icon: <ComplyAdvantageIcon />,
        description:
          'Check for AML hits, sanctions and adverse media on your corporate customers.',
      },
      {
        processType: 'loop',
        type: 'complyAdvantageKyc',
        title: 'ComplyAdvantage',
        variant: 'complyAdvantage',
        label: 'Individual',
        icon: <ComplyAdvantageIcon />,
        description:
          'Check for AML hits, sanctions, adverse media and PEP on your individual customers.',
      },
      {
        processType: 'loop',
        type: 'openCorporatesRisk',
        title: 'OpenCorporates',
        icon: <OpenCorporatesIcon />,
        variant: 'openCorporates',
        label: undefined,
        description:
          'Verify a company, and get access numerous datapoints to build logic upon.',
      },
      {
        processType: 'loop',
        type: 'kyckr',
        title: 'Kyckr',
        icon: <KyckrIcon />,
        variant: 'kyckr',
        description:
          'Discover Ultimate Beneficial Owners of your corporate customers.',
        hidden: !kycrAndCaoc,
      },
      {
        processType: 'loop',
        type: 'veriff',
        title: 'Veriff',
        icon: <VeriffIcon />,
        variant: 'veriff',
        label: undefined,
        description:
          'Perform identity verification and fraud prevention checks on individuals with Veriff.',
        hidden: !veriff,
      },
      {
        processType: 'loop',
        type: 'mitId',
        title: 'MitId',
        icon: <MitIdIcon />,
        variant: 'mitId',
        label: undefined,
        description: "Verify individuals' identity from Denmark with MitID.",
        hidden: !mitid,
      },
    ];

    return services.filter((item) => !item.hidden);
  }, []);
};
