import { ChevronDown } from 'lucide-react';
import { Icon } from '@radix-ui/react-select';

import { cn } from '@spektr/client/utils';
import { DropdownField } from '@spektr/moonraker-types';

export type DropdownContentProps = {
  field: DropdownField;
};

export const DropdownContent = ({ field }: DropdownContentProps) => (
  <div className="flex w-full flex-col gap-2">
    <div className="border-color-border-select-inverted bg-color-bg-select-inverted-trigger flex h-9 w-full items-center gap-2 rounded-md border px-3 py-2">
      <span className="text-spektr-zinc-500 whitespace-nowrap text-xs font-medium">
        {field.attributes?.placeholder}
      </span>
      <Icon asChild className="ml-auto shrink-0">
        <ChevronDown className="text-color-text-icon-secondary h-4 w-4" />
      </Icon>
    </div>
    <span
      className={cn(
        'h-0',
        'text-light-primary-subtext',
        'text-xs font-medium',
        'transition-all',
        { 'h-4': !!field.attributes?.helperText }
      )}
    >
      {field.attributes?.helperText}
    </span>
  </div>
);
