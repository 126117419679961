import { ComponentProps } from 'react';
import { DayPicker } from 'react-day-picker';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';

import { cn } from '@spektr/client/utils';

import { buttonVariants } from '../Button/variants';

import { CalendarDropdown } from './CalendarDropdown';
import { useCalendar } from './useCalendar';

export type CalendarProps = ComponentProps<typeof DayPicker>;

function Calendar({
  fromYear = 1950,
  toYear = 2050,
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  const { getDate, updateMonth, updateYear } = useCalendar(
    (props.selected as Date) ?? new Date()
  );

  return (
    <DayPicker
      fromYear={fromYear}
      toYear={toYear}
      captionLayout="dropdown-buttons"
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_dropdowns: 'flex items-center gap-2',
        caption_label: cn(
          'flex items-center px-2 py-1 rounded-md z-[1]',
          'text-sm font-medium opacity-50',
          'group-hover:bg-color-bg-accent group-hover:text-color-text-calendar-label--hover group-hover:opacity-100',
          'group-focus-within:ring-ring group-focus-within:outline-none group-focus-within:ring-1'
        ),
        dropdown: cn(
          'absolute top-0 right-0 bottom-0 left-0 p-0 z-[2]',
          'bg-none bg-transparent appearance-none opacity-0',
          'border-0 cursor-pointer'
        ),
        dropdown_icon: 'hidden',
        dropdown_month: 'relative group',
        dropdown_year: 'relative group',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'contained-secondary' }),
          'h-7 w-7 bg-transparent p-0 opacity-50',
          'hover:bg-color-bg-accent hover:opacity-100'
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell:
          'text-color-text-calendar-head-cell rounded-md w-8 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: cn(
          'relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-color-bg-accent',
          props.mode === 'range'
            ? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md'
            : '[&:has([aria-selected])]:rounded-md'
        ),
        day: cn(
          buttonVariants({ variant: 'contained-secondary' }),
          'bg-transparent',
          'h-8 w-8 p-0 font-normal aria-selected:opacity-100'
        ),
        day_range_start: 'day-range-start',
        day_range_end: 'day-range-end',
        day_selected:
          'bg-color-bg-calendar-selected-day text-color-text-calendar-selected-day hover:bg-color-bg-primary hover:text-color-text-calendar-selected-day--hover focus:bg-color-bg-primary focus:text-color-text-calendar-selected-day--hover',
        day_today: 'bg-color-bg-accent text-color-text-calendar-today',
        day_outside: 'text-color-text-calendar-not-today opacity-50',
        day_disabled: 'text-color-text-calendar-not-today opacity-50',
        day_range_middle:
          'aria-selected:bg-color-bg-accent aria-selected:text-color-text-calendar-range-selected',
        day_hidden: 'invisible',
        vhidden: 'hidden',
        ...classNames,
      }}
      components={{
        IconLeft: ({ className, ...props }) => (
          <ChevronLeftIcon className={cn('h-4 w-4', className)} {...props} />
        ),
        IconRight: ({ className, ...props }) => (
          <ChevronRightIcon className={cn('h-4 w-4', className)} {...props} />
        ),
        Dropdown: (props) => (
          <CalendarDropdown
            getDate={getDate}
            updateMonth={updateMonth}
            updateYear={updateYear}
            {...props}
          />
        ),
      }}
      defaultMonth={props.selected as Date}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
